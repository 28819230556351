import * as React from 'react';
import { IProductFilterRequestModel } from 'platform/api/product/models/request';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import CheckBox from 'rc-checkbox';
import { IProducerListResponseModel } from 'platform/api/producer/models/response';
import ProducerController from 'platform/api/producer';
import { infinityScrollMax } from 'platform/constants';
import useSubscriber from 'platform/hooks/use-subcriber';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import { buildFilters } from '../../../../services/helper';
import { getIsCheckInputKey, sendIsCheckInputKey, sendIsRestoreKey } from 'platform/hooks/useStorage';
import { filterCase } from '../..';
import { SVGRightSlack } from 'components/svg-components';
import ScrollPageLoader from 'components/page-loader/scrollLoader';
let timerId: NodeJS.Timeout | null;

const Producers = ({ body, onChange, Data, mobileFilterCase, onShowCase }: any) => {
  const [data, setData] = React.useState<any>();
  const [count, setCount] = React.useState<any>(10);
  const {showMobileFilter,showCase,isLoadingActiveGradient,allInLoading} = mobileFilterCase
  const [choosedList, setChoosedList] = React.useState<any>([]);
  const [lessThan1200, setWindowWidth] = React.useState<any>(false);
  
  const toggleItem = (id: number,isClear:boolean) => {
    sendIsRestoreKey()
    if(!isClear) {
      sendIsCheckInputKey(filterCase.Producers)
    }
    const bodyCopy = {...body};
    if (timerId) {
      clearTimeout(timerId);
    }
    if (bodyCopy.producerIds) {
      const query = new URLSearchParams(window.location.search);
      const existingIndex = bodyCopy.producerIds?.indexOf(id);
      if (existingIndex === -1) bodyCopy.producerIds.push(id);
      else bodyCopy.producerIds.splice(existingIndex, 1);
      bodyCopy.producerIds.length ? query.set('producerIds', bodyCopy.producerIds.join(',')) : query.delete('producerIds');
      window.history.replaceState(null,'',`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);

      timerId = setTimeout(() => {  
        onChange(body);
      }, 300);
    }
  }

  function handleWindowResize() {
    const windowWidth = window.innerWidth;
    if (windowWidth < 1200) {
      setCount(data?.length)
    } else {
      setCount(10)
    }
    setWindowWidth(windowWidth < 1200)
  }
  React.useEffect(() => { handleWindowResize() },[])
  React.useEffect(() => { 
    const filteredItem = Data?.filter((item:any) => item.isSelected)
    setChoosedList(filteredItem)
    setData(Data)
  },[Data] );

  return <>
    {data && data.length > 0 ?
        <>
          <h3 className="P-row-title" onClick={() => {if(lessThan1200)onShowCase();}}>
            <div className='viewaLLMain'>
              {Settings.translations.producer}
              {!showCase && <p className="viewaLL">
                {Settings.translations.View_All}
                <SVGRightSlack/>
              </p>}
            </div>

            {!!choosedList?.length && !showCase && <div className='ChossedList'>
              {choosedList?.map((item:any,index:number) => (
                <div key={index} className='itemChossed'>
                  {item.name}
                  <button className='closeCheckout' onClick={(e) => {
                    e.stopPropagation()
                    toggleItem(item.id,true)
                  }}>
                    <i className="P-close icon-Group-5032" />
                  </button>
                </div>
              ))}
            </div>}
          </h3>

          <div className={`P-row-content row-content-mobile ${(showMobileFilter && showCase === filterCase.Producers) ? 'showProducers' : ''}`}>
            {!getIsCheckInputKey(filterCase.Producers) && isLoadingActiveGradient ? <ScrollPageLoader /> : data?.slice(0, count)?.map((item:any) => 
              <label className="P-checkbox-row" onChange={() => !allInLoading && toggleItem(item.id, false)} key={item.id}>
                <div className='P-checkbox-row_side1'>
                  <CheckBox checked={body.producerIds?.includes(item.id)}/>
                  <span className="P-name" title={item.name}>{item.name}</span>
                </div>
                {/* <span>{item?.productsCount}</span> */}
              </label>
            )}
          </div>

          {Data.length > count && <button className='showItem' onClick={() => setCount((prevCount:any) => prevCount + 10)}>{Settings.translations.show_all}</button>}
        </>
        : null}
  </>;
};

export default Producers;