import * as React from 'react';
import { Link } from 'react-router-dom';
import LanguageSwitcher from './components/language-switcher';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import SearchInput from 'components/search-input';
import { Shared } from 'modules';
import Categories from './components/categories';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import Storage from 'platform/services/storage';
import Socket from 'platform/services/socket';
import NotificationController from 'platform/api/notification';
import Notifications from './components/notifications';
import ProductController from 'platform/api/product';
import LogoImage from 'assets/images/logo.svg';
import HeaderBanner from 'assets/images/baner_header.png';
import Broadcast from '../../platform/services/broadcast';
import { IProductSearcResponseModel } from 'platform/api/product/models/response';
import SearchPopup from './components/search';
import Connection from 'platform/services/connection';
import SearchHistory from 'platform/services/searchHistory';
import HelperComponent from 'platform/classes/helper-component';
import { memoize } from 'lodash';
import { AddressIconSvg, SVGComponentAcount, SVGComponentBasket, SVGComponentFavourite, SVGComponentMobileMenuIcon, SVGComponentMobileSearch, SVGComponentNotification, SVGComponentSearchSlack } from 'components/svg-components';
import { ResizerImgSizeDinamic } from 'platform/hooks/useImageSize';
import MobileHeaderPopUP from './components/mobile-menu';
import AddressMainPopUp from 'components/addressPopUp';
import UserAddressController from 'platform/api/userAddress';
import GlobalStorageData from 'platform/services/search';
import HeaderPromoLink from './components/promoLink';
import './style.scss';
import BannerController from 'platform/api/banner';

export enum BannerType {
    Header = 1,
    Main,
    Search
}

export enum addresOpenEnum {
    create = 1,
    change = 2
}

interface IState {
    authOpen: boolean;
    isAppsDropDown: boolean;
    categoryOpen: boolean;
    searchValue: string;
    notificationOpen: boolean;
    mobileSearchOpen: boolean;
    searchOpen: boolean;
    searchLoader: boolean;
    searchResult: IProductSearcResponseModel | null;
    searchHistoryShown: boolean;
    notificationIconNumber: number;
    mobileMenuOpen: boolean;
    scrollDown:boolean;
    favorite:number;
    addressValue:any;
    openAddressKey:number;
    headerBanner:any;
};


class Header extends HelperComponent<any, IState> {
    public state: IState = {
        authOpen: false,
        categoryOpen: false,
        isAppsDropDown: false,
        searchValue: '',
        mobileSearchOpen: false,
        notificationOpen: false,
        searchLoader: false,
        searchOpen: false,
        searchResult: null,
        searchHistoryShown: false,
        notificationIconNumber: 0,
        mobileMenuOpen: false,
        scrollDown:false,
        favorite:0,
        addressValue:{
            value:'',
            id:NaN
        },
        openAddressKey:NaN,
        headerBanner:null    
    };

    private timer: any;
    private header = React.createRef<HTMLDivElement>();
    private timeout:any = React.createRef();

    private categoryOpenLink = React.createRef<any>();
    private navLinkProps = {
        className: 'P-link',
        activeClassName: 'P-active',
        exact: true
    };

    public componentDidMount() {
        if (window.innerWidth < 901) {
            window.addEventListener('scroll', this.handleScroll);
        }
        window.addEventListener(DispatcherChannels.checkAddress,  this.fetchDefaultAddress);
        window.addEventListener(DispatcherChannels.toggleAuth,  this.toggleAuth);
        window.addEventListener(DispatcherChannels.openAddress,  this.HandleOpenAddress);
        Broadcast.subscribe(DispatcherChannels.StorageUpdate, this.storageUpdate);
        Storage.profile && this.configureNotifications();
        this.fetchHeaderBanner()
        if(Storage.profile || !Settings.guest) {
            this.fetchDefaultAddress();
        }
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        if (window.innerWidth < 901) {
            window.removeEventListener('scroll', this.handleScroll);
        }
        Broadcast.unsubscribe(DispatcherChannels.StorageUpdate, this.storageUpdate);
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    private storageUpdate = () => {
        this.forceUpdate();
    };

    public async configureNotifications() {
        this.timer = setInterval(async () => {
            const result = await NotificationController.GetUnseenList();
            if (result && result.success) {
                this.safeSetState({notificationIconNumber: result.data.unseen});
            }
        }, 10000)
    }

    private toggleAuth = () => {
        const {authOpen, mobileMenuOpen} = this.state;
        this.safeSetState({
            authOpen: !authOpen,
            mobileMenuOpen: !mobileMenuOpen || false 
        });
    };

    private openCategories = () => {
        const {categoryOpen} = this.state
        if(!categoryOpen) {
            this.safeSetState({categoryOpen: true});
        } else {
            this.safeSetState({categoryOpen: false});
        }
    };

    // private closeCategories = (e:any) => {
    //     this.safeSetState({categoryOpen: false});
    // };

    private closeCategories = (e?: MouseEvent) => {
        const {categoryOpen} = this.state;
        const canBeClosed = !e || (
            this.categoryOpenLink.current &&
            !this.categoryOpenLink.current.contains(e.target as Node)
        );

        if(categoryOpen && canBeClosed) {
            this.safeSetState({categoryOpen: false});
        }
    };

    private searchChange = async (value: any) => {
        clearTimeout(this?.timeout?.current)
        GlobalStorageData.changeText(value)
        if (this.timeout) {
            this.timeout.current = setTimeout(async () => {
                if (value?.trim()) {
                    this.safeSetState({
                        searchLoader: true,
                        searchOpen: false,
                        searchValue: value,
                        searchResult: null
                    }, async () => {
                        const data = await ProductController.Search(value);
                        if (data?.aborted) {
                            return
                        }
                        if (data?.data?.products?.length) {
                            this.safeSetState({
                                searchResult: data.data,
                                searchHistoryShown: false,
                                searchOpen: true
                            });
                        } else {
                            this.safeSetState({
                                searchResult: data.data,
                                searchHistoryShown: true,
                                searchOpen: false,
                            });
                            setTimeout(() => this.safeSetState({searchOpen: true}));
                        }
                        if (!data?.aborted) {
                            this.safeSetState({searchLoader: false});
                        }
                    });
                } else {
                    Connection.AbortAll();
                    this.safeSetState({
                        searchValue: '',
                        searchOpen: false,
                        searchLoader: false,
                        searchResult: {
                            products: SearchHistory.items,
                            categories: []
                        },
                        searchHistoryShown: true
                    });
                }
            }, 350);
        }
    };

    private closeSearch = (e: any) => {
        const targetClassName = e.target.className;
        
        if (!targetClassName.includes("mainInput")) {
            this.safeSetState({ searchOpen: false, searchHistoryShown: false });
        }
    };

    private toggleNotifications = (e?: Event | React.SyntheticEvent) => {
        e && e.stopPropagation();
        const {notificationOpen} = this.state;
        if (!notificationOpen) {
            this.configureNotifications();
        }
        this.safeSetState({notificationOpen: !notificationOpen});
    };

    private onNotificationSeenChange = (all: boolean) => {
        const {notificationIconNumber} = this.state;
        if (notificationIconNumber && notificationIconNumber > 0) {
            this.safeSetState({notificationIconNumber: all ? 0 : notificationIconNumber - 1});
        }
    };
    
    private searchFocus = async (e: React.SyntheticEvent) => {
        if ('id' in SearchHistory.items){
            window.localStorage.removeItem('searchHistory');
        }
        e.stopPropagation();
        const body = {
            productText: '',
            categoryIds: [],
            brandIds: [],
            producerIds: [],
            productIds: SearchHistory.items,
            activeIngredientIds: [],
            sortBy: undefined,
            hasDiscount: false,
            pageSize:30,
            pageNumber:1,
            paging: {
                count: 30,
                page: 1
            }
        };
        const result = await ProductController.GetList(body);
        const {searchValue} = this.state;
        if (!searchValue) SearchHistory.items.length && this.safeSetState({
            searchOpen: true,
            searchHistoryShown: true,
            searchResult: {
                products: result ? result.data.list : [],
                categories: []
            }
        }); else this.safeSetState({searchOpen: true, searchHistoryShown: false});
    };

    private searchSubmit = () => {
        const {searchValue} = this.state;

        if (searchValue) {
            this.safeSetState({searchOpen: false, searchLoader: false});
            window.routerHistory.push(`${ROUTES.PRODUCTS.MAIN}?text=${searchValue}`);
        } else {
            window.routerHistory.push(ROUTES.PRODUCTS.MAIN);
        }
    };

    private handleScroll = memoize(() => {
        const { pageYOffset: position } = window;
        this.safeSetState({ scrollDown: position > 10 });
    });

    private openDropDown = () => {
        this.safeSetState({ isAppsDropDown: !this.state.isAppsDropDown });
    };

    private closeDropDown:any = (e:any) => {
        if(e.target.nodeName !== "svg" && e.target.nodeName !== "line") {
            this.safeSetState({ isAppsDropDown: false });
        }
    };

    private fetchHeaderBanner = async () => {
        const headerBanner = await BannerController.GetHeaderBanner(BannerType.Header);
        this.safeSetState({ headerBanner });
    }
    
    private fetchDefaultAddress = async (key?:any) => {
        await Storage.fetchMainDefaultAddress();
        this.setState({addressValue:{
            value:Storage?.currentAddress?.addressText,
            id:Storage?.currentAddress?.id
        }})

    };

    private HandleOpenAddress = async (key?:any) => {
        const result = await UserAddressController.GetList();
        if(key === addresOpenEnum.create) {
            this.setState({openAddressKey:addresOpenEnum.create})
        } else if(key === addresOpenEnum.change || result?.data?.length) {
            this.setState({openAddressKey:addresOpenEnum.change})
        } else {
            this.setState({openAddressKey:addresOpenEnum.create})
        }
    };

    private HandleCloseAddress = () => {
        this.setState({openAddressKey:NaN})
    };

    private onClick = (e:any) => {
        e.stopPropagation()

        const { searchValue,searchResult,searchOpen } = this.state;
        if(Array.isArray(searchResult) && !searchOpen) {
            this.searchChange(searchValue)
        }
    };

    
    private showAll = () => {
        const query = new URLSearchParams(window.location.search);
        const categoryIds = query.get('categoryIds');
        const categoryKey = categoryIds ? `categoryIds=${categoryIds}` : ''
        const newUrl = `${ROUTES.PRODUCTS.MAIN}?${categoryKey}&sortBy=1&page=1&text=${GlobalStorageData.text}`;
        window.location.href = newUrl;
        this.props.onClose();
    }

    private onBannerItemClick = (item: any) => {
        const changeRoute = () => {
          const newUrl = `${item?.bannerDetailLink}`;
          window.routerHistory.replace(newUrl);
        };
    
        changeRoute()
    };

    public render() {
        const {
            authOpen,
            categoryOpen,
            notificationIconNumber,
            notificationOpen,
            searchOpen,
            isAppsDropDown,
            searchValue,
            searchResult,
            searchLoader,
            searchHistoryShown,
            scrollDown,
            addressValue,
            openAddressKey,
            headerBanner
        } = this.state;
        const { basketCount } = this.props;

        return (
            <header id={`header`} ref={this.header} className={`G-flex G-flex-align-center ${scrollDown  ? 'scroll-down' : '' }`}>
                {!!headerBanner?.data?.length && <div className='P-header-language-natali' onClick={() => this.onBannerItemClick(headerBanner?.data[0])}>
                    <img src={headerBanner?.data[0]?.bannerWebImage} className='header_banner_img'/>
                    <p className='header_banner_title' style={{color:headerBanner?.data[0]?.textColor}} >{headerBanner?.data[0]?.information}</p>
                </div>}

                <div className="header-inner G-flex G-flex-align-center">   
                    <Link to={ROUTES.MN} className="P_logo_container" aria-label='logo'>
                        <img alt={'logo'} height={66} width={103}  src={LogoImage} className="logo"/>
                    </Link>

                    <button className='button-category-list' 
                        ref={this.categoryOpenLink}
                        onClick={this.openCategories}
                    >
                        <SVGComponentMobileMenuIcon/>
                        <p className='text-category'>{Settings.translations.category}</p>
                    </button>
                    {categoryOpen && <Categories categoryOpenkey={categoryOpen} onClose={this.closeCategories}/>}
                    
                    <div className="P-search-wrapper P-desktop-search-box">
                        <SearchInput
                            onClick={this.onClick}
                            onFocus={this.searchFocus}
                            onChange={this.searchChange}
                            onSubmit={this.searchSubmit}
                            clearSearch={true}
                            loading={searchLoader}
                            disableRemoveOnNavigate={true}
                            withSubmit={true}
                        />
                        <button className='seeAllButtonSearch' onClick={this.showAll}>
                            <SVGComponentMobileSearch/>
                            <span>{Settings.translations.only_search}</span>
                        </button>

                        {searchOpen && searchResult && <SearchPopup
                            onClose={this.closeSearch}
                            onSubmit={this.searchSubmit}
                            data={searchResult}
                            searchText={searchValue}
                            historyShown={searchHistoryShown}
                        />}
                    </div>
                    <LanguageSwitcher initialLoading={this.props.initialLoading}/>

                    {(Storage.profile || !Settings.guest && Settings.token) ? 
                    <div className='choseAddressBar' onClick={() => this.HandleOpenAddress()}>
                        <div className='addressAndIcon'>
                            <span className='addressIcon'><AddressIconSvg/></span>
                            {addressValue.value ? 
                                <p className='addressValue'>{addressValue.value}</p> : 
                                <p className='addressPlaceholder'>{Settings.translations.addressPlacerholder}</p>
                            }                 
                        </div>
                        {addressValue.value && <span className='tectChange'>{Settings.translations.edit_text}</span>}
                    </div> : null}


                    {!!openAddressKey && <AddressMainPopUp 
                        defaultValueAdress={addressValue}
                        openAddressKey={openAddressKey} 
                        onClose={this.HandleCloseAddress}
                        HandleOpenAddress={this.HandleOpenAddress}
                        makedefault={(defaultValue:any) => {
                            this.setState({addressValue:{
                                value:defaultValue.value,
                                id:defaultValue.id
                            }})
                        }}
                    />}



                    <div className='P_link_container'>
                        <LanguageSwitcher initialLoading={this.props.initialLoading}/>
                        {/* 
                        {!Settings.guest && Settings.token && <button onClick={this.toggleNotifications}
                            className="P-link-icon notification_title"
                        >
                            <SVGComponentNotification/>
                            {!!notificationIconNumber && <span className='count_basket'>{notificationIconNumber > 99 ? '99+' : notificationIconNumber}</span>}
                            <span className="P-link-icon_title">{Settings.translations.Notification_title}</span>
                        </button>} */}
                        
                        {Storage.profile || !Settings.guest ? <Link  to={ROUTES.CART} className="P-link-icon" aria-label="CART">
                            <SVGComponentBasket/>
                            {!!basketCount && <span className='count_basket'>{basketCount > 99 ? '99+' : basketCount}</span>}
                            <span className="P-link-icon_title">{Settings.translations.basket_title}</span>
                        </Link> : 
                        <div className='P-link-icon'  onClick={this.toggleAuth}>
                            <SVGComponentBasket/>
                            <span className="P-link-icon_title">{Settings.translations.basket_title}</span>
                        </div>}

                        {Storage.profile || !Settings.guest ? <Link to={ROUTES.PROFILE.FAVORITES.MAIN} className="P-link-icon" aria-label="Favorite">
                            <SVGComponentFavourite/>
                            <span className="P-link-icon_title">{Settings.translations.favour_title}</span>
                        </Link> : 
                        <div className='P-link-icon'  onClick={this.toggleAuth}>
                            <SVGComponentFavourite/>
                            <span className="P-link-icon_title">{Settings.translations.favour_title}</span>
                        </div>}

                        {Storage.profile || !Settings.guest ? <Link to={ROUTES.PROFILE.MAIN} className="P-link-icon P-profile" aria-label="Profile">
                            {Storage?.profile?.photoPath ? 
                                <img alt="Profile" src={ResizerImgSizeDinamic(Storage.profile.photoPath,[100,100])} className="P-image"/> : 
                                <SVGComponentAcount/>
                            }
                            <span className="P-link-icon_title">{Storage.profile?.firstName || Settings.translations.log_in}</span>
                        </Link> : 
                        <div className='P-link-icon'  onClick={this.toggleAuth}>
                            <SVGComponentAcount/>
                            <span className="P-link-icon_title">{Settings.translations.log_in}</span>
                        </div>}

                        {authOpen && <Shared.Auth onClose={this.toggleAuth}/>}
                        {notificationOpen &&
                            <Notifications 
                                onClose={this.toggleNotifications}
                                onSeenChange={this.onNotificationSeenChange}
                            />
                        }
                    </div>

                    <div className='mobileIconButton'>
                        <p className='buttonMobile searchButton'>
                            <SVGComponentMobileSearch/>
                        </p>
                        {/* <p className='buttonMobile' onClick={() => this.openDropDown()}>
                            <SVGComponentMobileMenuIcon/>
                        </p> */}
                    </div>
                </div>
                
                {isAppsDropDown && <MobileHeaderPopUP onClose={this.closeDropDown}/>}
                <HeaderPromoLink/>
            </header>
        );
    }
}

export default Header;
