import * as React from 'react';
import * as DateTime from 'react-datetime';
import { Moment } from 'moment';

import HelperComponent from 'platform/classes/helper-component';
import LoaderContent from 'components/loader-content';
import Settings from 'platform/services/settings';
import { IUserModifyRequestModel } from 'platform/api/user/models/request';
import Storage from 'platform/services/storage';
import { validateForm } from './services/helper';
import UserController from 'platform/api/user';
import { GenderEnum } from 'platform/api/user/constants/enums/gender';
import Select from 'components/select';
import { GenderDropdown } from 'platform/constants/dropdowns';
import { IDropdownOption } from 'platform/constants/interfaces';
import { formatDate } from 'platform/services/helper';
import slackIcon from "assets/images/icons/ic_arrow.svg"

import './style.scss';
import ClickOutside from 'components/click-outside';
import ShimmerLoading from 'components/loader-content/shimmerLoading';

interface IState {
  form: IUserModifyRequestModel;
  edited: boolean;
  submited: boolean;
  submitLoading: boolean;
  openDate:boolean
  isLoading:boolean
};

class DetailsForm extends HelperComponent<any, IState> {

  constructor(props) {
    super(props);
    this.datetimePickerRef = React.createRef();
  }

  public state: IState = {
    edited: false,
    submited: false,
    submitLoading: false,
    openDate:false,
    form: {
      firstName: Storage.profile?.firstName,
      lastName: Storage.profile?.lastName,
      email: Storage.profile?.email,
      phone: Storage.profile?.phoneNumber,
      gender: Storage.profile?.gender,
      dateOfBirth: Storage.profile?.dateOfBirth,
      file: undefined
    },
    isLoading:true
  }

  public componentDidMount(): void {
    if(this.state.form.firstName !== undefined){
      this.setState({ isLoading:false });
    }
  }
  
  public componentDidUpdate(prevProps:any) {
    if (Storage.profile !== prevProps.profile) {
      // Check if any property of the profile has changed
      if (Storage.profile?.lastName !== this.state.form.lastName && this.state.form.lastName === undefined) {
        this.setState({
          isLoading:false,
          form: {
            firstName: Storage.profile?.firstName,
            lastName: Storage.profile?.lastName,
            email: Storage.profile?.email,
            phone: Storage.profile?.phoneNumber,
            gender: Storage.profile?.gender,
            dateOfBirth: Storage.profile?.dateOfBirth,
            file: undefined
          },
        });
      }
    }
  }

  private get formValidation() {
    const { submited, form } = this.state;
    return validateForm?.call(form, submited);
  }

  private changeField = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { form } = this.state;
    form[e.currentTarget.name] = e.currentTarget.value;
    this.safeSetState({ form, edited: true });
  }

  private changeGender = (chosen: IDropdownOption<GenderEnum>) => {
    const { form } = this.state;
    form.gender = chosen.value;
    this.safeSetState({ form, edited: true });
  }

  private changeDateOfBirth = (chosen: Moment) => {
    const { form } = this.state;
    form.dateOfBirth = chosen.format('YYYY-MM-DD');
    this.safeSetState({ form, edited: true });
  }

  private submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.safeSetState({ submited: true }, async () => {
      this.formValidation.valid && this.safeSetState({ submitLoading: true }, async () => {
        const { form } = this.state;
        const { file } = this.props;
        const formData:any = new FormData();

        const newForm:any = {
          ...form,
          file
        }
        
        formData.append("Email", newForm?.email);
        formData.append("FirstName", newForm.firstName);
        formData.append("LastName", newForm.lastName);
        formData.append("Gender", newForm.gender);
        formData.append("DateOfBirth", newForm.dateOfBirth);
        formData.append("File", newForm.file);
          
        const result = await UserController.Update(formData);
        // const result = await UserController.UploadCover(formData);
        if (result.data) window.location.reload();
        else this.safeSetState({ submitLoading: false });
      });
    });
  }

  private isValidDate = (date: moment.Moment) => {
    const invalidDate = new Date();
    const minDate = new Date();
    const minDateFormat = minDate.setFullYear(1920);
    return !date.isSameOrAfter(invalidDate) && date.isSameOrAfter(minDateFormat);
  }

  private handleOpenDate = (key:boolean) => {
    this.setState({ openDate: key });
  }

  public render() {
    const { form, submitLoading, openDate, isLoading } = this.state;

    if(isLoading){return <ShimmerLoading colCount={2} childColCount={5}  MClass={"myAcount"} className={"myAcountChildShimmer"}/>} 
    return (
      <form className="P-profile-details-form G-main-form P-form-block  P-login-form-redesign">
        <div className="G-main-form-field">
          <p className="G-input-top-label">{Settings?.translations?.first_name}</p>
          <input
            name="firstName"
            value={form.firstName || ""}
            className={`G-main-input ${this.formValidation.errors.firstName ? 'G-invalid-field' : ''}`}
            onChange={this.changeField}
          />
        </div>
        <div className="G-main-form-field">
          <p className="G-input-top-label">{Settings.translations.last_name}</p>
          <input
            name="lastName"
            value={form.lastName || ""}
            className={`G-main-input ${this.formValidation.errors.lastName ? 'G-invalid-field' : ''}`}
            onChange={this.changeField}
          />
        </div>
        <div className="G-main-form-field">
          <p className="G-input-top-label">{Settings.translations.email}</p>
          <input
            name="email"
            value={form.email || ""}
            className={`G-main-input ${this.formValidation.errors.email ? 'G-invalid-field' : ''}`}
            onChange={this.changeField}
          />
        </div>
        <div className="G-main-form-field">
          <p className="G-input-top-label">{Settings.translations.phone_number}</p>
          <input
            name="phone"
            value={form.phone || ""}
            className="G-main-input phoneField"
            disabled={true}
          />
        </div>
        <div className="G-main-form-field">
          <p className="G-input-top-label">{Settings.translations.gender}</p>
          <Select<GenderEnum>
            options={GenderDropdown()}
            value={form.gender}
            className={`G-main-select`}
            onChange={this.changeGender}
            hideIcon={true}
          />
          <img src={slackIcon} className='imgSlack'/>
        </div>
        
        <ClickOutside className='selectDatBorn' onClickOutside={() => this.handleOpenDate(false)}>
          <div className="G-main-form-field" onClick={(e) => {
            e.stopPropagation()
            this.handleOpenDate(true)
          }}>
            <p className="G-input-top-label">{Settings.translations.date_of_birth}</p>
            <DateTime
              onChange={this.changeDateOfBirth}
              closeOnSelect={false}
              timeFormat={false}
              open={openDate}
              isValidDate={this.isValidDate}
              inputProps={{
                id:"dataPicker",
                disabled:true,
                value: form.dateOfBirth ? formatDate(form.dateOfBirth, false) : '',
                readOnly: true,
                className: `G-main-input ${this.formValidation.errors.dateOfBirth ? 'G-invalid-field' : ''}`,
              }}
            />
            {/* <img src={slackIcon} className='imgSlack'/> */}
          </div>
        </ClickOutside>

        {<LoaderContent
          className="G-main-button"
          loading={submitLoading}
          onClick={this.submit}
          id={'save_btn'}
        >{Settings.translations.save}</LoaderContent>}
      </form>
    );
  }
}

export default DetailsForm;