import Connection from '../../services/connection';
class ChatController {

  public static sendMessage = (formData: any): Promise<any> => {
    if (Array.isArray(formData)) {
      console.log('All items in formData array:');
      formData.forEach((item, index) => {
        console.log(`Item ${index + 1}:`, item);
      });
    } else if (typeof formData === 'object') {
      console.log('All items in formData object:');
      Object.entries(formData).forEach(([key, value]) => {
        console.log(`${key}:`, value);
      });
    } else {
      console.log('formData is neither an array nor an object:', formData);
    }
  
    const result = Connection.POST({
      action: 'SendMessage',
      controller: 'Chat',
      body:formData,
      noneJSONBody: true,
    });

    return result;
  };

  public static fetchMessages = (body: any): any => {
    const result = Connection.POST({
      action: 'GetMessages',
      controller: 'Chat',
      body,
    });

    return result;
  };
};

export default ChatController;