import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';

import Settings from 'platform/services/settings';


import './style.scss';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import LoaderContent from 'components/loader-content';
import ClickOutside from "../click-outside";
import PageLoader from 'components/page-loader';
import BannerController from 'platform/api/banner';
import { BannerType } from 'components/header';

interface IState {
    search: string;
    additionSearch?:any
};

interface IProps extends RouteComponentProps<any> {
    withSubmit?: boolean;
    clearSearch?: boolean;
    disableRemoveOnNavigate?: boolean;
    loading?: boolean;


    onClick?(e: React.SyntheticEvent<HTMLInputElement>): void;

    onClose?(): void;

    onFocus?(e: React.SyntheticEvent<HTMLInputElement>): void;

    onBlur?(e: React.SyntheticEvent<HTMLInputElement>): void;

    onChange?(value: string): void;

    onSubmit?(value: string): void;
};

class SearchInput extends HelperPureComponent<IProps, IState> {

    public state: IState = {
        search: '',
        additionSearch:[]
    };

    public componentDidMount() {
        this.fetchData()
    }

    public componentDidUpdate = (prevProps: any) => {
        if (!prevProps.disableRemoveOnNavigate) {
            if (prevProps.location.pathname !== this.props.location.pathname || prevProps.location.search !== this.props.location.search) {
                this.removeText()
            }
        }
    }
    
    private fetchData = async () => {
        const result = await BannerController.GetHeaderBanner(BannerType.Search);
        
        this.safeSetState({ additionSearch: result.data });
    }

    private change = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const {onChange} = this.props;
        this.safeSetState({search: e.currentTarget.value});
        onChange && onChange(e.currentTarget.value);
    }

    private removeText = () => {
        const {onChange} = this.props;

        this.safeSetState({search: ''});
        onChange && onChange('');
    }

    private onBannerItemClick = (item: any) => {
        const changeRoute = () => {
          const newUrl = `${item?.bannerDetailLink}`;
          window.location.replace(newUrl);
        };
    
        changeRoute()
    };

    public render() {
        const {withSubmit, clearSearch, loading, onClose, onClick, onFocus, onBlur} = this.props;
        const {search,additionSearch} = this.state;

        return (
            <ClickOutside onClickOutside={onClose}>
                <form className={`P-search-input ${withSubmit ? 'P-search-input-submit' : ''}`} onSubmit={(e:any) => e.preventDefault()}>
                    <input
                        className="G-main-input mainInput"
                        value={search}
                        onClick={onClick}
                        onBlur={onBlur}
                        onChange={this.change}
                    />

                    {!!search || <div className='placeholderSearchField'>
                        <span className='placeholder'>{Settings.translations.search}</span>
                        {!!additionSearch?.length && 
                        <div className='innerLinks'>
                            {`(`}
                            
                            {additionSearch?.map((val:any,index:number) => (
                                <div key={index} className='searchLink' onClick={() => this.onBannerItemClick(val)}>
                                    {val?.information}
                                    {index !== additionSearch.length - 1 && ','}
                                </div>
                            ))}
                            {`)`}
                        </div>}
                    </div>}

                    {loading && <PageLoader />}
                    {search && clearSearch &&
                        <button type="button" id={'remove_search'} className="P-remove-search" onClick={this.removeText}>
                            <i className="icon-Group-5032"/>
                        </button>
                    }
                </form>
            </ClickOutside>
        );
    }
}

export default withRouter(SearchInput);
