import * as React from 'react';
import Radio from 'components/radio';
import {PaymentTypeEnum} from 'platform/constants/enums';
import Storage from 'platform/services/storage';

import {PaymentTypeEnumItems} from '../../constants';
import IPay from './components/i-pay';
import {IOrderResultResponseModel} from 'platform/api/order/models/response';
import {IOrderCreateRequestModel} from "../../../../../platform/api/order/models/request";
import Ameria from "./components/ameria";

export interface IPaymentMethod {
    resultInfo?: IOrderResultResponseModel;
    form?: IOrderCreateRequestModel;
    callback: (e: React.SyntheticEvent) => void;
    setCardType: (e: number) => void;
}

const PaymentMethod = React.memo(({resultInfo, callback, setCardType, form}: IPaymentMethod) => {
    const [type, setType] = React.useState(PaymentTypeEnum.Cash);
    const [loading, setLoading] = React.useState(false);
    const total = () => {
        const query = new URLSearchParams(window.location.search);
        const item = query.get('total');
        return resultInfo ? Number(item) + resultInfo.deliveryFee : Number(item);
    }

    const pay = (e: React.SyntheticEvent) => {
        setLoading(true);
        callback(e);
    };

    const chooseType = (item: PaymentTypeEnum) => {
        setType(item);
        const query = new URLSearchParams(window.location.search);

        if ((item === 2 || item === 3 || item === 0) && Storage.profile) {
            setCardType(2);
        }

        if (item) {
            query.set('paymentType', item.toString());
        } else {
            query.delete('paymentType')
        }
        const scrollY = window.scrollY;
        window.routerHistory.push(`${window.location.pathname}?${query.toString()}`);
       setTimeout(() => {
           window.scrollTo(0, scrollY);
        }, 0);
    }

    const child = (item: PaymentTypeEnum) => {
        const content = {
            [PaymentTypeEnum.Cash]: null,
            [PaymentTypeEnum.IPay]: <IPay/>,
            // [PaymentTypeEnum.Ameria]: <Ameria setCardType={(e: number) => setCardType(e)}/>
        };
        return content[item];
    }

    return <div className="P-choose-payment-type-section">
        <div className="P-payment-types G-flex G-flex-justify-start">
            {
                form?.deliveryType === 1 ? PaymentTypeEnumItems().map((item, index) =>
                    <div key={index} className={item.class}>
                        <Radio<PaymentTypeEnum> callback={(x: PaymentTypeEnum) => chooseType(x)} value={item.type} isChecked={type === item.type}>
                            {item.image ? <img src={item.image} alt=""/> : null}
                            <span>{item.name}</span>
                            {type === item.type && child(item.type)}
                        </Radio>
                    </div>)
                    :
                    PaymentTypeEnumItems().filter(item => item.type !== PaymentTypeEnum.PosTerminal && item.type !== PaymentTypeEnum.Cash).map((item, index) =>
                        <div key={index} className={item.class}>
                            <Radio<PaymentTypeEnum> 
                                callback={(x: PaymentTypeEnum) => chooseType(x)} 
                                value={item.type}
                                isChecked={type === item.type}
                            >
                                {item.image ? <img src={item.image} alt=""/> : null}
                                <span>{item.name}</span>
                                {type === item.type && child(item.type)}
                            </Radio>
                        </div>
                    )
            }
        </div>
    </div>
});

export default PaymentMethod;