import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import environment from 'platform/services/environment';
import { SVGAddrresProfileIcon, SVGBonusCardProfileIcon, SVGFavoriteProfileIcon, SVGOrderProgileIcon, SVGProfileIcon } from 'components/svg-components';

export const leftSideOptions = () => [
  {
    name: Settings.translations.my_profile,
    path: ROUTES.PROFILE.MAIN,
    imgUrl:SVGProfileIcon
  },
  // {
  //   name: Settings.translations.payment_method,
  //   path: ROUTES.PROFILE.MY_WALLET,
  //   imgUrl:SVGProfileIcon
  // },
  {
    name: Settings.translations.orders_history,
    path: ROUTES.PROFILE.ORDERS.MAIN,
    imgUrl:SVGOrderProgileIcon
  },
  {
    name: Settings.translations.favorites,
    path: ROUTES.PROFILE.FAVORITES.MAIN,
    imgUrl:SVGFavoriteProfileIcon
  },
  {
    name: Settings.translations.addresses,
    path: ROUTES.PROFILE.ADDRESSES.MAIN,
    imgUrl:SVGAddrresProfileIcon
  },
  {
    name: Settings.translations.bonus_card,
    path: ROUTES.PROFILE.BONUS_CARD,
    imgUrl:SVGBonusCardProfileIcon
  },
]
