import * as React from 'react';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import './scrollLoader.scss';



interface IState {
  show?: boolean;
};

class ScrollPageLoader extends HelperPureComponent<IState, IState> {

  public render() {

    return ( 
      <div className='parentLoader'>
        <span className="loader">{""}</span>
      </div>
    )
  }
}

export default ScrollPageLoader;
