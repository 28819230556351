import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './newsSlider.scss';
import Settings from 'platform/services/settings';
import { SVGComponentNextArrowIcon, SVGComponentPrevArrowIcon } from 'components/svg-components';
import { Link } from 'react-router-dom';
import NewsController from 'platform/api/news';
import ROUTES from 'platform/constants/routes';

const NewsSlide: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [resize, setResize] = useState<any>();
    const [isDragging, setIsDragging] = useState<boolean>(false); // Track swipe state

    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow:resize > 1700 ? 3 : 1,
        slidesToScroll: 1,
        nextArrow: <div><SVGComponentNextArrowIcon /></div>,
        prevArrow: <div><SVGComponentPrevArrowIcon /></div>,
        variableWidth: true,
        swipeToSlide: true,
        responsive: [],
        beforeChange: () => setIsDragging(true), // Set dragging state before slide change
        afterChange: () => setIsDragging(false), // Reset dragging state after slide change
    };

    async function fetchData() {
        const response: any = await NewsController.GetList({
            page: 1,
            count: 10,
        });

        if (response.success) {
            setData(response?.data?.list);
        }
    }

    // Handle window resize
    const handleResize = () => setResize(window.innerWidth);

    useEffect(() => {
        fetchData();
        handleResize()
    }, []);

    const handleMouseDown = () => {
        setIsDragging(false); 
    };

    const handleMouseMove = () => {
        setIsDragging(true); // Mark as dragging on mouse move
    };

    const handleMouseUp = () => {
        setTimeout(() => setIsDragging(false), 100); // Slight delay to reset dragging
    };

    const handleClick = (e: React.MouseEvent) => {
        if (isDragging) {
            e.preventDefault(); // Prevent navigation if dragging
        }
    };

    return (
        <section className='G-page P-home-discounted-products B-news-slider'>
            <h2 className="G-page-title">{Settings.translations.LatestNews}</h2>
            {data?.length <= 3 && resize > 1700 ? <div className='C-news-slider-child'>
                {data?.map(({ id, name, shortDescription, coverPhoto }) => {
                    return ( 
                        <Link 
                            to={{ pathname: ROUTES.NEWS.DETAILS.replace(':id', id) }} 
                            key={id} 
                            className='news-slider_item' 
                        > 
                            <img className='news-slider_item_img' src={coverPhoto} alt={name} /> 
                            <p className='news-slider_item_title'>{name}</p> 
                            <p className='news-slider_item_description'>{shortDescription}</p> 
                        </Link> 
                    ); 
                })} 
            </div> : 
            (<div className='C-news-slider-child-slider'>
                <Slider {...settings} arrows={true}>
                    {data?.slice(0,10)?.map(({ id, name, shortDescription, coverPhoto }) => {
                        return ( 
                            <Link 
                                to={{ pathname: ROUTES.NEWS.DETAILS.replace(':id', id) }} 
                                key={id} 
                                className='news-slider_item' 
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onClick={handleClick}
                            > 
                                <img className='news-slider_item_img' src={coverPhoto} alt={name} /> 
                                <p className='news-slider_item_title'>{name}</p> 
                                <p className='news-slider_item_description'>{shortDescription}</p> 
                            </Link> 
                        ); 
                    })} 
                </Slider> 
            </div>)}
            {data?.length <= 3 && <Link className='showItem' to={{ pathname: ROUTES.NEWS.MAIN }}>{Settings.translations.show_all}</Link>}
        </section> 
    ); 
}; 

export default NewsSlide;
