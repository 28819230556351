import * as React from 'react';

import Modal from 'components/modal';
import SignIn from './components/signin';
import SignUp from './components/signup';
import SendCode from './components/send-code';
import { ModalContentEnum } from './constants/enums';
import { IAuthQueryData } from './constants/interfaces';
import NewPassword from './components/new-password';
import HelperComponent from 'platform/classes/helper-component';

import './style.scss';
import BonusCardRegister from './components/bonusCardRegister';
import RestoreVerify from './components/verify';

interface IProps {
  data?: IAuthQueryData | null;
  onClose(): void;
}

interface IState {
  loading: boolean;
  contentType: ModalContentEnum;
  errorMessage: string;
  activeData: object;
  bonusCardData:any
}

class Auth extends HelperComponent<IProps, IState> {

  public state: IState = {
    loading: false,
    contentType: ModalContentEnum.SendCode,
    errorMessage: '',
    activeData: {},
    bonusCardData:{}
  };
  
  public componentWillUnmount() {
    super.componentWillUnmount();
    document.body.style.position = 'unset'
  }
  
  private contentTypes = {
    [ModalContentEnum.SignIn]: () => <SignIn onTypeChange={this.onTypeChange} />,
    [ModalContentEnum.SendCode]: () => <SendCode onTypeChange={this.onTypeChange} activeData={this.state.activeData} />,
    [ModalContentEnum.Verify]: () => <RestoreVerify onTypeChange={this.onTypeChange} activeData={this.state.activeData} />,
    [ModalContentEnum.NewPassword]: () => <NewPassword onTypeChange={this.onTypeChange} />,
    [ModalContentEnum.SignUp]: () => <SignUp onTypeChange={this.onTypeChange}  bonusCardData={this.state.bonusCardData}/>,
    [ModalContentEnum.AttachBonusCard]: () => <BonusCardRegister onChangeBonusCard={this.onChangeBonusCard} onTypeChange={this.onTypeChange} activeData={this.state.activeData}/>,
  };

  private onTypeChange = <ActiveData extends object>(contentType: ModalContentEnum, activeData?: ActiveData) => {
    this.safeSetState({ contentType, activeData: activeData || {} })
  };
  private onChangeBonusCard = (userCardData:any) => this.safeSetState({ bonusCardData:userCardData  });


  public render() {
    const { onClose } = this.props;
    
    return (
      <Modal
        className="P-auth-modal"
        // onClose={onClose}
        onCloseXButton={onClose}
      >
        <this.ActiveContent />
      </Modal>
    );

    // return (
    //   <SignUp onTypeChange={this.onTypeChange} />
    // );
  }

  private get ActiveContent() {
    const { contentType } = this.state
    return this.contentTypes[contentType];
  }

}

export default Auth;
