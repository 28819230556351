import React, { useRef } from 'react';
import './Sidebar.scss';
import { Link } from 'react-router-dom';
import { MdOutlineFavoriteBorder, MdFavorite } from 'react-icons/md';
import MainButton from 'components/Button/mainButton/mainButton';
import { ResizerImgSizeStatic } from 'platform/hooks/useImageSize';
import { formatPrice } from 'platform/services/helper';
import { PromotionTypeEnum } from 'platform/constants/enums';
import BasketController from 'platform/api/basket';
import FavoriteController from 'platform/api/favorite';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import ROUTES from 'platform/constants/routes';
import { SVGComponentCloseIcon } from 'components/svg-components';

const Sidebar = ({ siderBarData, onClose, lang, handleUpdate }) => {
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const HandleClose = (e:any) => {
        e.preventDefault();
        e.stopPropagation();
        onClose(null);
    };

    const addToCart = async (e:any, data:any) => {
        e.preventDefault();
        await BasketController.Change({
            productId: data.id,
            productQuantity: 1,
            isPackage: false,
        });
        window.dispatchEvent(new CustomEvent(DispatcherChannels.CartUpdate));
        window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
        handleUpdate()
    };

    const toggleFavorite = async (e:any, data:any) => {
        e.preventDefault();
        if (timeout.current) clearTimeout(timeout.current);

        timeout.current = setTimeout(async () => {
            await FavoriteController.AddOrRemove(data.id);
            handleUpdate()
        }, 300);
    };

    return (
        <div className={`sidebar ${siderBarData ? 'open' : ''}`}>
            <button onClick={HandleClose} className="close-btn"><SVGComponentCloseIcon/></button>
            
            <div className="product_content">
                {siderBarData && siderBarData.map((newData:any) => {

                    return (
                        <>
                            <p className='title_slidebar'>{newData?.title}</p>
                            {newData?.products?.map((item:any) => {
                                const isDiscount = !!item.promotion?.result && item.promotion.promotionType === PromotionTypeEnum.Discount;
    
                                return (
                                    <Link 
                                        key={item.id}
                                        to={ROUTES.PRODUCTS.DETAILS.replace(':id', item.id).replace(':slug', item.slugs[lang])} 
                                        className="product-item"
                                    >
                                        <div className="P-image-parent">
                                            {item.imagePath ? (
                                                <div 
                                                    className="P-image" 
                                                    style={{ background: `url('${ResizerImgSizeStatic(item.imagePath)}') center/contain no-repeat` }} 
                                                />
                                            ) : (
                                                <div className="P-image" />
                                            )}
                                        </div>
                                        
                                        <div className='right_content'>
                                            <h3 className="P-product-name">{item.title}</h3>
        
                                            <div className="favourite_item_product" 
                                                onClick={(e) => toggleFavorite(e, item)}
                                            >
                                                {!item.isFavorite ? (
                                                    <MdOutlineFavoriteBorder fontSize={28} />
                                                ) : (
                                                    <MdFavorite fontSize={28} className="filledIcon" />
                                                )}
                                            </div>
        
                                            <div className="price_container">
                                                <span className="P-price">
                                                    {formatPrice(isDiscount ? item.promotion.result : item.price)}
                                                </span>
                                                {isDiscount && (
                                                    <del className="P-without-discount-price">
                                                        {formatPrice(item.price)}
                                                    </del>
                                                )}
                                            </div>
        
                                            <MainButton
                                                loading={false}
                                                onClick={(e) => addToCart(e, item)}
                                                isaddedtobasket={item.isAddedToBasket}
                                            />
                                        </div>
                                    </Link>
                                );
                            })}
                        </>
                       
                    )
                })}
            </div>
        </div>
    );
};

export default Sidebar;
