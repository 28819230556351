import React, { useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick';
import './style.scss';
import Settings from 'platform/services/settings';
import { SVGComponentNextArrowIcon, SVGComponentPrevArrowIcon } from 'components/svg-components';
import BrandController from 'platform/api/brand';
import ROUTES from 'platform/constants/routes';
import { CategoryPageTypeEnum } from 'modules/pages/products/pages/list';

const BrandsSlide: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false); // Track swipe state

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 300,
      slidesToShow: data?.length < 5 ? data?.length : 5,
      slidesToScroll: 1,
      nextArrow: <div><SVGComponentNextArrowIcon /></div>,
      prevArrow: <div><SVGComponentPrevArrowIcon /></div>,
      swipeToSlide: true,
      responsive: [
          {
              breakpoint: 1850,
              settings: {
                  slidesToShow: data?.length < 5 ? data?.length : 4,
                  slidesToScroll: 1,
              }
          },
          {
              breakpoint: 1024,
              settings: {
                  slidesToShow: data?.length < 5 ? data?.length : 3,
                  slidesToScroll: 1
              }
          },
          {
              breakpoint: 600,
              settings: {
                  variableWidth: true,
                  slidesToShow: 2,
                  slidesToScroll: 1,
              }
          }
      ],
      beforeChange: () => setIsDragging(true), // Set dragging state before slide change
      afterChange: () => setIsDragging(false), // Reset dragging state after slide change
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const result = await BrandController.GetBrandHomeList();
            setData(result?.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const changeRoute = (id: number) => {
        if (isDragging) return; // Prevent navigation if dragging
        const newUrl = `${ROUTES.PRODUCTS.MAIN}?brandIds=${id}&sortBy=1&page=1&CategoryPageType=${CategoryPageTypeEnum.brandPage}`;
        window.location.href = newUrl;
    };

    if (!data) return null;

    return (
        <section className='G-page P-home-discounted-products B-Brands'>
            <h2 className="G-page-title">{Settings.translations.ours_brand}</h2>

            <div className='C-brands-child'>
                <Slider {...settings} arrows={true}>
                    {data?.map(({ codeSap, logoPath }) => {
                        return (
                            <div 
                                key={codeSap} 
                                className='brands_item' 
                                onClick={() => changeRoute(codeSap)}
                            >
                                <img className='brands_item_img' src={logoPath} />
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </section>
    );
};

export default BrandsSlide;
