import * as React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import { contactHeaderPhoneNumberOne, contactHeaderPhoneNumberTwo,contactEmail } from 'platform/constants/contact-info';
import LogoImage from 'assets/images/logo.png';
import './style.scss';
import {useEffect} from "react";
import StaticController from "../../platform/api/static";
import { AiFillFacebook } from 'react-icons/ai';
import { FaInstagramSquare } from 'react-icons/fa';

const currentYear = new Date().getFullYear();
const icons = 'icon-Group-5504 P-icons';

function Footer() {
    const [aboutRotateClass, setAboutRotateClass] = React.useState([icons]);
    const [informationRotateClass, setInformationRotateClass] = React.useState([icons]);
    const [usefulRotateClass, setUsefulRotateClass] = React.useState([icons]);
    const [contactRotateClass, setContactRotateClass] = React.useState([icons]);

    const lang:any=Settings.language
    return (
        <footer>
            <div className="footer-inner">
                <div className="P-content">
                    <div className='P-logo_footer'>
                        <img alt='logoImg' height={60} src={LogoImage} className="P-logo G-mr-auto" />
                    </div>

                    <div className="P-column P-mobile-column">
                        <div className="P-mobile-drop G-flex G-flex-justify-between G-flex-align-center">
                            <h3 className="G-clr-main">{Settings.translations.information}</h3>
                        </div>
                        <Link className={"P-hidden-items"} to={`${lang}/pharmacies`}>{Settings.translations.pharmacies}</Link>
                        <Link className={"P-hidden-items"} to={ROUTES.PRIVACY_POLICY}>{Settings.translations.privacy_policy}</Link>
                        <Link className={"P-hidden-items"} to={ROUTES.TERMS_OF_USE}>{Settings.translations.terms_of_use}</Link>
                        <Link className={"P-hidden-items"} to={ROUTES.CONTACT}>{Settings.translations.contactUs}</Link>

                    </div>
              
                    <div className="P-column P-mobile-column">
                        <div className="P-mobile-drop G-flex G-flex-justify-between G-flex-align-center">
                            <h3 className="G-clr-main">{Settings.translations.useful_links}</h3>
                            {/* <i className={usefulRotateClass.join(' ')} /> */}
                        </div>

                        <div className='social_link_icon'>
                            <Link to={{ pathname: "https://www.facebook.com/natali.group/?locale=hy_AM" }} aria-label="Read more about facebook" target="_blank" rel="noopener noreferrer">
                                <AiFillFacebook size="23px" />
                            </Link>

                            <Link to={{ pathname: "https://www.instagram.com/natali_pharm_/" }} aria-label="Read more about instagram" target="_blank" rel="noopener noreferrer">
                                <FaInstagramSquare size="23px" />
                            </Link>
                        </div>
                    </div>
                    <div className="P-column P-mobile-column">
                        <div className="P-mobile-drop G-flex G-flex-justify-between G-flex-align-center">
                            <h3 className="G-clr-main">{Settings.translations.contact}</h3>
                            {/* <i className={contactRotateClass.join(' ')} /> */}
                        </div>
                        <a href={`tel:${contactHeaderPhoneNumberTwo}`} aria-label="Call us at +374 55556460" className={"contact-tel"}>
                            <b>{`(${contactHeaderPhoneNumberTwo.slice(0, 4)})${contactHeaderPhoneNumberTwo.slice(4)}`}</b>
                        </a>
                        <a href={`tel:${contactHeaderPhoneNumberOne}`} aria-label="Call us at +374 55556460" className={"contact-tel"}>
                            <b>{`(${contactHeaderPhoneNumberOne.slice(0, 4)})${contactHeaderPhoneNumberOne.slice(4)}`}</b>
                        </a>
                        <p className={"contact-name"}>{contactEmail}</p>
                        <p className={"contact-name"}>{Settings.translations.contact_address}</p>
                    </div>
                </div>
                <h4 className="P-copyright">
                    {Settings.translations.copyright} &copy; {currentYear} 
                </h4>
            </div>
        </footer>
    )
}

export default Footer;
