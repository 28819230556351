export const createFormAndSubmit = (path:any, params:any, method = 'post') => {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];
      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};


export const createFormAndSubmitAmeria = (path:any, params:any, method = 'get') => {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  // for (const key in params) {
  //   if (params.hasOwnProperty(key)) {
  //     const hiddenField = document.createElement('input');
  //     hiddenField.type = 'hidden';
  //     hiddenField.name = key;
  //     hiddenField.value = params[key];
  //     form.appendChild(hiddenField);
  //   }
  // }

  document.body.appendChild(form);
  form.submit();
};