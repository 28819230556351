import * as React from 'react';
import HelperComponent from "platform/classes/helper-component";
import {IProductFilterRequestModel} from 'platform/api/product/models/request';
import ROUTES from 'platform/constants/routes';
import Storage from 'platform/services/storage';
import {buildFilters, priceConfig} from '../../services/helper';
import Brands from './components/brands';
import Producers from './components/producers';
import ActiveIngredients from './components/active-ingredients';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import PriceRange from './components/price-range';
import Settings from 'platform/services/settings';

import { ICategoryListResponseModel } from 'platform/api/category/models/response';
import { removeIsCheckInputKey, sendIsRestoreKey } from 'platform/hooks/useStorage';
import ProducerController from 'platform/api/producer';
import BrandController from 'platform/api/brand';
import ActiveIngredientController from 'platform/api/activeIngredient';
import './style.scss';
import { CategoryPageTypeEnum } from '../..';


interface IProps {
    onChange(): void;
    productLoading?:boolean;
    FilterData:any
    showMobileFilter?:any;
    openFilter:any
};

interface IState {
    categoryOpen: boolean;
    mobileOpen: boolean;
    filterOpen: boolean;
    openSearch: boolean;
    maxPrice: number;
    body: IProductFilterRequestModel;
    childCategories: ICategoryListResponseModel[][];
    childCategoryOpen: boolean;
    activeId:number | null | string
    AllData:any
    showCase:number;
    isLOadingBrand:boolean,
    isLOadingProducer:boolean,
    isLoadingActiveGradient:boolean,
};

export enum filterCase {
    brands = 1,
    Producers = 2,
    ActiveIngredients = 3
}

class Filter extends HelperComponent<IProps, IState> {
    constructor(props:any) {
        super(props);
        this.fetchAllWithEmptyBodyFilters = this.debounce(this.fetchAllWithEmptyBodyFilters.bind(this), 300);
        this.fetchAllWithChangesBodyFilters = this.debounce(this.fetchAllWithChangesBodyFilters.bind(this), 300);
    }
    
    private debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
        let timeout: ReturnType<typeof setTimeout>;
        return function (this: any, ...args: Parameters<T>) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }
    
    public state: IState = {
        categoryOpen: false,
        mobileOpen: false,
        filterOpen: false,
        openSearch: false,
        maxPrice: priceConfig.maxPrice,
        body: {
            brandIds: [],
            producerIds: [],
            categoryIds: [],
            activeIngredientIds: [],
            CollectionId:null
        },
        AllData:{
            brandData: [],
            producerData: [],
            activeIngredientData: [],
        },
        childCategories:[],
        childCategoryOpen:true,
        activeId:null,
        showCase:0,
        isLOadingBrand:false,
        isLOadingProducer:false,
        isLoadingActiveGradient:false
    };

    public componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener(DispatcherChannels.refetchNewCategoryData, this.eventHandler);

        this.fetchAllWithEmptyBodyFilters();
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener(DispatcherChannels.refetchNewCategoryData, this.eventHandler);
    }

    private eventHandler = () => {
        sendIsRestoreKey()
        this.fetchToChangeCategory()
    };

    public openCloseSearch = () => {
        this.safeSetState({openSearch: !this.state.openSearch});
    }
    
    private bodyChange = (body: IProductFilterRequestModel) => {
        this.fetchAllWithChangesBodyFilters(body);
    }

    private fetchToChangeCategory = () => {
        window.scrollTo(0, 0);
        this.fetchAllWithEmptyBodyFilters();
    }

    private clearFilter = () => {
        const {body} = this.state;
        body.brandIds = [];
        body.activeIngredientIds = [];
        body.producerIds = [];
        const query = new URLSearchParams(window.location.search);
        const CategoryPageType = query.get('CategoryPageType');
        if(CategoryPageType !== `${CategoryPageTypeEnum.brandPage}`) {
            query.delete('brandIds');
        }
        query.delete('activeIngredientIds');
        query.delete('producerIds');
        const updatedQueryString = query.toString();
        window.history.replaceState(null,'',`${ROUTES.PRODUCTS.MAIN}?${updatedQueryString}`);

        sendIsRestoreKey()
        
        this.fetchAllWithChangesBodyFilters(body);
    }

    private fetchAllWithEmptyBodyFilters = () => {
        this.fetchBrandData()
        this.fetchProducerData()
        this.fetchActiveIngredientData()
        this.safeSetState({body: buildFilters()});
    }

    private fetchAllWithChangesBodyFilters = (body:any) => {
        const {onChange} = this.props;

        this.fetchBrandData()
        this.fetchProducerData()
        this.fetchActiveIngredientData()
        this.safeSetState({body}, onChange);
    }

    private fetchProducerData  = async () => {
        const body = { ...buildFilters() };
        this.setState({isLOadingProducer:true})

        ProducerController.GetList({
            categoryIds:body.categoryIds,
            producerIds:body.producerIds,
            activeIngredientIds:body.activeIngredientIds,
            brandIds:body.brandIds,
            CollectionId:body.CollectionId
        }).then(result => {
            if (result.success) {
                this.setState({
                    AllData:{
                        ...this.state.AllData,
                        producerData:result.data
                    }
                })
                this.setState({isLOadingProducer:false})
                removeIsCheckInputKey(filterCase.Producers)
            }
        })
    };

    private fetchActiveIngredientData  = async () => {
        const body = { ...buildFilters() };
        this.setState({isLoadingActiveGradient:true})
    
        ActiveIngredientController.GetList({
            categoryIds:body.categoryIds,
            producerIds:body.producerIds,
            activeIngredientIds:body.activeIngredientIds,
            brandIds:body.brandIds,
            CollectionId:body.CollectionId
        }).then(result => {
            if (result.success) {
                this.setState({
                    AllData:{
                        ...this.state.AllData,
                        activeIngredientData:result.data
                    }
                })
                this.setState({isLoadingActiveGradient:false})
                removeIsCheckInputKey(filterCase.ActiveIngredients)
            }
        })
    };

    private fetchBrandData = async () => {
        const body = { ...buildFilters() };
        this.setState({isLOadingBrand:true})

        BrandController.GetList({
            categoryIds:body.categoryIds,
            producerIds:body.producerIds,
            activeIngredientIds:body.activeIngredientIds,
            brandIds:body.brandIds,
            CollectionId:body.CollectionId
        }).then(result => {
            if (result.success) {
                this.setState({
                    AllData:{
                        ...this.state.AllData,
                        brandData:result.data
                    }
                })
                this.setState({isLOadingBrand:false})
                removeIsCheckInputKey(filterCase.brands)
            }
        })
    };
    
    private ShowMobileCase = (body:number) => {
        const {showCase} = this.state;
        if(!showCase) {
            this.setState({showCase:body})
        } 
    }

    private CloseCategoryList = () => {
        const { openFilter } = this.props;
        const {showCase} = this.state;
        if(showCase) {
            this.setState({showCase:0})
            this.clearFilter()
        } else {
            openFilter()
        }
    }

    public render() {
        const {body, AllData, showCase, isLOadingBrand, isLOadingProducer, isLoadingActiveGradient} = this.state;
        const query = new URLSearchParams(window.location.search);
        const CategoryPageType = query.get('CategoryPageType');
        const { showMobileFilter, productLoading} = this.props;
        const { brandData, producerData, activeIngredientData } = AllData;
        const updatedQueryString = ((CategoryPageType !== `${CategoryPageTypeEnum.brandPage}`) ? query.has('brandIds') : false) || query.has('activeIngredientIds') || query.has('producerIds')
        const allInLoading = isLOadingBrand || isLOadingProducer || isLoadingActiveGradient /** || productLoading */

        return (    
            <div className={`P-products-filter mobile-filter ${showMobileFilter ? 'showFilter' : ''}`}>
                <div className="P-row-wrap categoryTitleContainer">
                    <div className='categories-title_container'>
                        <button className='closeButton' onClick={this.CloseCategoryList}>
                            <i className="P-close icon-Group-5032" />
                        </button>
                        {<h2 className="categories-title">{Settings.translations.filter}</h2>}
                    </div>
                    {!!updatedQueryString && !!showCase && <button className="confirmButton" onClick={() => this.setState({showCase:0})}>{'Confirm'}</button>}
                    {!!updatedQueryString && !showCase && <button className="confirmButton clearButton" onClick={this.clearFilter}>{Settings.translations.clear_Filter}</button>}
                </div>

                {CategoryPageType !== `${CategoryPageTypeEnum.brandPage}` && <div className={`P-row-wrap ${(showMobileFilter && showCase !== filterCase.brands && showCase !== Number(0)) ? 'hideBrands' : ''}`}>
                    <Brands mobileFilterCase={{showMobileFilter,showCase,isLOadingBrand,allInLoading}} Data={brandData} body={body} onChange={this.bodyChange} onShowCase={() => {this.ShowMobileCase(filterCase.brands)}}/>
                </div>}

                <div className={`P-row-wrap ${(showMobileFilter && showCase !== filterCase.Producers && showCase !== Number(0)) ? 'hideProducer' : ''}`}>
                    <Producers mobileFilterCase={{showMobileFilter,showCase,isLOadingProducer,allInLoading}} Data={producerData} body={body} onChange={this.bodyChange} onShowCase={() => {this.ShowMobileCase(filterCase.Producers)}}/>
                </div>

                <div className={`P-row-wrap ${(showMobileFilter && showCase !== filterCase.ActiveIngredients && showCase !== Number(0)) ? 'hideActiveGradients' : ''}`}>
                    <ActiveIngredients mobileFilterCase={{showMobileFilter,showCase,isLoadingActiveGradient,allInLoading}} Data={activeIngredientData} body={body} onChange={this.bodyChange} onShowCase={() => {this.ShowMobileCase(filterCase.ActiveIngredients)}}/>
                </div>
            </div>
        );
    }
}

export default Filter;
