import * as React from 'react';
import { IProductFilterRequestModel } from 'platform/api/product/models/request';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import CheckBox from 'rc-checkbox';
import { getIsCheckInputKey, removeIsCheckInputKey, sendIsCheckInputKey, sendIsRestoreKey } from 'platform/hooks/useStorage';
import { filterCase } from '../..';
import { SVGRightSlack } from 'components/svg-components';
import ScrollPageLoader from 'components/page-loader/scrollLoader';
let timerId: NodeJS.Timeout | null;

const Brands = ({ body, onChange, Data, onShowCase, mobileFilterCase }: any) => {
  const [data, setData] = React.useState<any>();
  const [choosedList, setChoosedList] = React.useState<any>([]);
  const [count, setCount] = React.useState<any>(10);
  const [lessThan1200, setWindowWidth] = React.useState<any>(false);


  const {showMobileFilter,showCase,isLOadingBrand,allInLoading} = mobileFilterCase
  
  const toggleItem = (id: number,isClear:boolean) => {
    sendIsRestoreKey()
    if(!isClear) {
      sendIsCheckInputKey(filterCase.brands)
    }
    const bodyCopy = {...body};
    if (timerId) {
      clearTimeout(timerId);
    }
    if (bodyCopy.brandIds) {
      const query = new URLSearchParams(window.location.search);
      const existingIndex = bodyCopy.brandIds?.indexOf(id);
      if (existingIndex === -1) bodyCopy.brandIds.push(id);
      else bodyCopy.brandIds.splice(existingIndex, 1);
      
      bodyCopy.brandIds.length ? query.set('brandIds', bodyCopy.brandIds.join(',')) : query.delete('brandIds');
      window.history.replaceState(null,'',`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);

      timerId = setTimeout(() => {  
        onChange(body);
      }, 300);
    }
  }
  
  function handleWindowResize() {
    const windowWidth = window.innerWidth;
    if (windowWidth < 1200) {
      setCount(data?.length)
    } else {
      setCount(10)
    }
    setWindowWidth(windowWidth < 1200)
  }
  React.useEffect(() => { 
    handleWindowResize() 
  },[])

  React.useEffect(() => { 

    const filteredItem = Data?.filter((item:any) => item.isSelected)
    setChoosedList(filteredItem)
    setData(Data)

  },[Data] );

  return <>
    {data && data?.length > 0 ?
        <React.Fragment>
          <h3 className="P-row-title" onClick={() => {if(lessThan1200)onShowCase();}}>
            <div className='viewaLLMain'>
              {Settings.translations.brand}
              {!showCase && <p className="viewaLL">
                {Settings.translations.View_All}
                <SVGRightSlack/>
              </p>}
            </div>

            {!!choosedList?.length && !showCase && <div className='ChossedList'>
              {choosedList?.map((item:any,index:number) => (
                <div key={index} className='itemChossed'>
                  {item.name}
                  <button className='closeCheckout' onClick={(e) => {
                    e.stopPropagation()
                    toggleItem(item.id,true)
                  }}>
                    <i className="P-close icon-Group-5032" />
                  </button>
                </div>
              ))}
            </div>}
          </h3>

          <div className={`P-row-content row-content-mobile ${(showMobileFilter && showCase === filterCase.brands) ? 'showBrands' : ''}`}>
            {!getIsCheckInputKey(filterCase.brands) && isLOadingBrand ? <ScrollPageLoader /> : data?.slice(0, count)?.map((item:any, index:number) => 
            <label className="P-checkbox-row" onChange={() => !allInLoading && toggleItem(item.id, false)} key={item.id}>
              <div className='P-checkbox-row_side1'>
                <CheckBox checked={body.brandIds?.includes(item.id)}/>
                <span className="P-name" title={item.name}>{item.name}</span>
              </div>
              {/* <span>{item?.productsCount}</span> */}
            </label>)}
          </div>
            
          {Data.length > count && <button className='showItem' onClick={() => setCount((prevCount:any) => prevCount + 10)}>{Settings.translations.show_all}</button>}
        </React.Fragment> : null}
  </>;
};

export default Brands;