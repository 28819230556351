import * as React from 'react';
import {withRouter, RouteComponentProps, Link} from 'react-router-dom';
import generic from 'platform/decorators/generic';
import ROUTES from 'platform/constants/routes';
import {byRoute} from 'platform/decorators/routes';
import ProductController from 'platform/api/product';
import {IProductDetailsResponseModel} from 'platform/api/product/models/response';
import Info from './components/info';
import Images from './components/images';
import Bundles from './components/bundles';
import Similar from './components/similar';
import HelperComponent from 'platform/classes/helper-component';
import PageLoader from 'components/page-loader';
import './style.scss';
import ScrollPageLoader from 'components/page-loader/scrollLoader';
import EmptyState from 'components/empty-state';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import AllProductsList, { PageTypeEnum, PlacementEnum } from 'modules/pages/home/components/all-products';


interface IRouteParams {
    id: string,
    slug: string
}

interface IState {
    data?: IProductDetailsResponseModel;
    isEmpty:boolean;
    mainLoading:boolean;
}

type IProps = RouteComponentProps<IRouteParams>;

@generic<IProps>(withRouter)
@byRoute(ROUTES.PRODUCTS.DETAILS)
class Details extends HelperComponent<IProps, {}> {
    constructor(props:any) {
        super(props);
        this.fetchDebounceData = this.debounce(this.fetchDebounceData.bind(this), 200);
    }

    public state: IState = { 
        isEmpty:false,
        mainLoading:true
    };
    
    public componentDidMount() {
        this.fetchData();
        window.dispatchEvent(new CustomEvent(DispatcherChannels.checkRoute, { detail: { route: "products/details" } }));
        window.addEventListener(DispatcherChannels.productDetailUpdate, this.fetchDebounceData);
    }

    public componentWillUnmount() {
        window.dispatchEvent(new CustomEvent(DispatcherChannels.checkRoute, { detail: { route: "" } }));

    }

    public componentDidUpdate(prevProps: IProps) {
        const {id} = this.props.match.params;
        id !== prevProps.match.params.id && this.safeSetState({data: undefined,isEmpty:false}, () => {
            this.safeSetState({mainLoading:true})
            this.fetchDebounceData()
        });
    }

    private debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
        let timeout: ReturnType<typeof setTimeout>;
        return function (this: any, ...args: Parameters<T>) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }

    private fetchDebounceData = (e?:any): any => this.fetchData(e);

    private fetchData = async (callBack?:any) => {
        const {id} = this.props.match.params;
        const {slug} = this.props.match.params;
        const result = await ProductController.GetDetails(+id,slug);
        const dataNotFound = !!result.data


        this.safeSetState({data: result.data,isEmpty:!dataNotFound});
        callBack && callBack()
    }
    
    private changeDetails = (data: IProductDetailsResponseModel) => {
        this.safeSetState({data,mainLoading:false})
    };
    
    public render() {
        const {data, isEmpty, mainLoading} = this.state;
        if(!data && isEmpty) return <EmptyState/>
        return (
            <section className="G-page P-product-details-page">
                <div className="P-content">
                    {!!data?.images.length ? <Images data={data} onChange={this.changeDetails}/> : <ShimmerLoading  className={'detailSHimmerLoading'} />}
                    {data ? <Info data={data} mainLoading={mainLoading} fetchData={this.fetchDebounceData}/> : 
                        <ShimmerLoading MClass={'Shot-DetShimmer'} className={'RightSideShimmerLoading'} colCount={2} childColCount={7}/>}
                    {data ? <Bundles data={data}/> : <ShimmerLoading style={{width:'100%', height:'130px'}} />}
                </div>

                {/* <AllProductsList params={{placementType:PlacementEnum.Product,pageId:id}}/> */}
                <AllProductsList params={{placementType:PlacementEnum.SuggestedProducts,pageId:93697}}/>

                <Similar data={data}/>
            </section> 
        )
    }
}

export default Details;