import { IProps } from 'components/confirm-modal';
import { Hour24Img } from 'components/svg-components';
import BrandController from 'platform/api/brand';
import NotificationController from 'platform/api/notification';
import { ProductSortEnum } from 'platform/api/product/constants/enums';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import Storage from 'platform/services/storage';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import PopupHeader from './promoPopUp';

export enum CollectionPromo {
  Promo,
  Discount,
  Collection
}

const HeaderPromoLink = () => {
  const [promoCollections, setData] = useState<any>(null); // Assuming type 'IState['data']'
  const [loading, setLoading] = useState<boolean>(false);
  const containerRef = useRef(null);
  const [scrollStatus, setScrollStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await BrandController.GetPromoHeaderList(CollectionPromo.Promo);
      setData(result?.data?.promoCollections);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [promoCollections]);

  useEffect(() => {
    const checkScroll = () => {
      if (containerRef.current) {
        const { scrollWidth, clientWidth }:any = containerRef.current;
        setScrollStatus(scrollWidth > clientWidth);
      }
    };

    checkScroll();
  }, [promoCollections]);

  useEffect(() => {
    fetchData();
  }, []);

  const changeRoute = (id: number) => {
    const newUrl = `${ROUTES.PRODUCTS.MAIN}?CollectionId=${id}&sortBy=1&page=1`;
    window.location.href = newUrl;
  };

  if(!promoCollections)return null
  return (
    <div ref={containerRef} className={`P-header-link-natali ${scrollStatus ? 'hideLastItem' : ''}`}>
      <span className='hourImage'><Hour24Img/></span>
      {promoCollections?.map(({title,id}:any) => {
        const query = new URLSearchParams(window.location.search);
        const CollectionId = query.get('CollectionId');
        return (
          <button key={id} 
            className={`P-link ${Number(CollectionId) === id ? 'activePromo' : ''}`}
            onClick={() => changeRoute(id)}
          >{title}</button> 
        )
      })}
      
      <button  
        className={`viewAllLink`}
        onClick={() => setIsOpen(!isOpen)}
      >{Settings.translations.View_All}</button> 
      {isOpen && <PopupHeader items={promoCollections} />}
    </div>
  );
};

export default HeaderPromoLink;

