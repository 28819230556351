import * as React from 'react';
import { Shared } from 'modules';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import { IProductDetailsResponseModel } from 'platform/api/product/models/response';
import FavoriteController from 'platform/api/favorite';
import Settings from 'platform/services/settings';
import UserController from 'platform/api/user';
import ConfirmModal from 'components/confirm-modal';
import PhotoStorage from 'platform/services/photoStorage';
import Slider from "react-slick";
import GtagData from "../../../../../../../platform/services/gtagData";
import { MdFavorite } from 'react-icons/md';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import './style.scss';
import { formatPercenteages } from 'platform/services/helper';

interface IProps {
  data: IProductDetailsResponseModel;
  onChange(data: IProductDetailsResponseModel): void;
}

interface IState {
  activeId: number;
  photosPackIndex: number;
  confirmModal: boolean;
}

class Images extends HelperPureComponent<IProps, IState> {

  public state: IState = {
    activeId: 0,
    photosPackIndex: 0,
    confirmModal: false,
  };

  public componentDidMount() {
    const { data } = this.props;
    this.safeSetState({ activeId: data.images[0].id }, async () => {
      const { onChange } = this.props;
      const result = await Promise.all(data.images.map(item => PhotoStorage.getURL(item.path).then(url => ({
        ...item,
        path: url,
      }))));
      onChange({
        ...data,
        images: result,
      });
    });
  }

  private toggleFavorite = async (e: React.SyntheticEvent, item: any) => {
    e.preventDefault();
    const { data, onChange } = this.props;
    const result = await FavoriteController.AddOrRemove(item.id);
    GtagData(data,"add_to_wishlist")
    result && result.success && onChange({
      ...data,
      isFavorite: !data.isFavorite,
    });
  }

  private addSpecialProduct = async () => {
    const { data, onChange } = this.props;
    const result = await UserController.UpdatePreferredProductList({ newProductId: data.id });
    result && result.success && onChange({
      ...data,
      isSpecial: !data.isSpecial,
    });
    this.closeConfirmModal();
  }

  private closeConfirmModal = () => {
    this.safeSetState({ confirmModal: false });
  }

  private zoom = (e: React.MouseEvent) => {
    const zoomer = e.currentTarget as HTMLElement;
    const offsetX = e.nativeEvent.offsetX;
    const offsetY = e.nativeEvent.offsetY;

    const x = offsetX / zoomer.offsetWidth * 100;
    const y = offsetY / zoomer.offsetHeight * 100;
    
    zoomer.style.backgroundPosition = x + '% ' + y + '%';
    
  }
  
  public render() {
    const { data } = this.props;
    const { confirmModal } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false,
      swipe:true,
    }

    const isDiscount = data?.discountedPrice < data.packagePrice;

    return (
      <div className="P-product-details-images">
        {isDiscount && <div className='percent_discount_favicon'>{formatPercenteages(data.discountPercent)}</div>}

        {
          !Settings.guest && 
          <div className='favourite_item_product' onClick={e => this.toggleFavorite(e, data)}>
            {!data.isFavorite ? <MdOutlineFavoriteBorder fontSize={28}/> : <MdFavorite fontSize={28} className='filledIcon'/>}
          </div>
        }

        <div className="P-current-image">
          <Slider {...settings}>
            {data.images.map(item => <div  key={item.id} >
              <div className="I-zoomable-image" onMouseMove={this.zoom} style={{ backgroundImage: `url("${item.path}")` }}>
                <img src={item.path}  alt={`${item.id}`}/>
              </div>
            </div>)}
          </Slider>
        </div>
        
        {confirmModal && <ConfirmModal
          title={Settings.translations.special_product}
          text={Settings.translations.special_product_confirm}
          onConfirm={this.addSpecialProduct}
          onClose={this.closeConfirmModal}
        />}
      </div>
    );
  }
};

export default Images;
