import Connection from '../../services/connection';
import { IResponse, IPagingResponse } from '../../constants/interfaces';
import { IBrandListResponseModel } from './models/response';
import { IBrandListRequestModel } from './models/request';

class BrandController {

  private static controller = 'brand';

  public static GetList = (body: any): Promise<any> => {
    const result = Connection.POST({
      body,
      action: 'list',
      controller: BrandController.controller,
    });

    return result;
  };


  public static GetPromoHeaderList = (promoColectionEnum: any): Promise<any> => {
    const result = Connection.GET({
      action: `GetActiveCollections?promoType=${promoColectionEnum}`,
      controller: 'Promo',
    });

    return result;
  };

  public static GetPromoCollectionCover = (promoColectionId: any): Promise<any> => {
    const result = Connection.GET({
      action: `GetProductsInBrand?id=${promoColectionId}`,
      controller: 'Promo',
    });

    return result;
  };

  public static GetBrandDetail = (promoColectionId: any): Promise<any> => {
    const result = Connection.GET({
      action: `MainPageDetail/${promoColectionId}`,
      controller: 'Brand',
    });

    return result;
  };

  public static GetBrandHomeList = (): Promise<any> => {
    const result = Connection.GET({
      action: `MainPageList`,
      controller: 'Brand',
    });

    return result;
  };
};
export default BrandController;