import React, { useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { SVGComponentNextArrowIcon, SVGComponentPrevArrowIcon } from 'components/svg-components';
import './style.scss';
import Settings from 'platform/services/settings';
import BrandController from 'platform/api/brand';
import { CollectionPromo } from 'components/header/components/promoLink';
import ROUTES from 'platform/constants/routes';




const Collection: React.FC = () => {
    const [data,setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
        const result = await BrandController.GetPromoHeaderList(CollectionPromo.Collection);
        setData(result?.data?.promoCollections);
        } catch (error) {
        console.error('Error fetching data:', error);
        } finally {
        setLoading(false);
        }
    }, [data]);

    useEffect(() => {
        fetchData();
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        autoplay:true,
        speed: 300,
        slidesToShow: data?.length > 3 ? 3 : data.length,
        slidesToScroll: 1,
        nextArrow: <div><SVGComponentNextArrowIcon /></div>,
        prevArrow: <div><SVGComponentPrevArrowIcon /></div>,
    
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 700,
                settings: {
                variableWidth:true,
                slidesToShow: 1,
                slidesToScroll: 1,
                },
            },
        ],
    };

    
    const changeRoute = (id: number) => {
        const newUrl = `${ROUTES.PRODUCTS.MAIN}?CollectionId=${id}&sortBy=1&page=1`;
        window.location.href = newUrl;
    };

    return (
        <section className='G-page P-home-discounted-products C-collection'>
            {!!data?.length && <h2 className="G-page-title">{Settings.translations.collection_title}</h2>}

            <div className='C-collection-child'>
                <Slider {...settings} arrows={true}>
                    {data?.map(({coverPhoto,id}) => (
                        <button 
                            key={id} className='collection_item'
                            onClick={() => changeRoute(id)}
                        >
                            <img
                                className='collection_item_img'
                                src={coverPhoto}
                                alt={`Collection item ${id}`}
                            />
                        </button> 
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default Collection;
