import * as React from 'react';
import ROUTES from 'platform/constants/routes';
import {byRoute} from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import Settings from 'platform/services/settings';
import Details from '../news/detail';
import moment from 'moment';
import './style.scss';
import {Link} from "react-router-dom";
import BlogController from 'platform/api/blog';
import GlobalStorageData from 'platform/services/search';
import ScrollPagination from 'components/pagination/scrollPagination';
import { ResizerImgSizeDinamic } from 'platform/hooks/useImageSize';


interface IState {
    data?: any;
    loading: boolean;
    isLoading: boolean;
    pageCount:number
};

@byRoute([ROUTES.BLOG.MAIN])
class Blog extends HelperComponent<{}, IState> {
    private pageLimitSize: number = 30;

    public state: IState = {
        loading: false,
        isLoading: true,
        pageCount:0,
        data: {
            lists: []
        }
    };

    public async componentDidMount() {
        this.fetchData()
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
    }

    private fetchData = async (pageNumber: any = 1) => {
        this.safeSetState({loading: true});
        const body = {
            pageNumber,
            pageSize: this.pageLimitSize,
        };
        
        const response:any = await BlogController.GetList(body)
        if(response.success) {
            this.setState({
                data:{lists:response?.data?.list},
                pageCount:response?.data?.pageCount,
                loading:false
            })
        }
    }
    
    public render() {
        const { data,loading,pageCount } = this.state;
        const { lists } = data
        const firstItem = lists?.find((val:any,index:number) => index === 0)
        
        return (
            <section id='P-blog-page' className="G-page P-blog-page">
                <Link to={{
                        pathname: ROUTES.BLOG.DETAILS.replace(':id', firstItem?.id),
                        state: { lists }
                    }}
                    className='mainItem'
                >
                    <div className='description_field'>
                        <p className='date'>{moment(firstItem?.createdDate).format('MMMM Do YYYY')}</p>
                        <p className='mainTitle'>
                            {firstItem?.title}
                        </p>
                        <p className='description'> 
                            {firstItem?.shortDescription}
                        </p>
                    </div>
                   {firstItem?.imagePath && <img alt='mainImg' className='mainImg' src={ResizerImgSizeDinamic(firstItem?.imagePath,[600,600])}/>}
                </Link>

                {!!lists.length &&
                <div className='allItemsContainer'>
                    <p className='allItemsTitle'>{Settings.translations.LatestNews}</p>
                    <div className='allItems'>
                        {lists?.map(({id,title,shortDescription,createdDate,imagePath}) => {
                            return (
                                <Link key={id} 
                                    to={{pathname: ROUTES.BLOG.DETAILS.replace(':id', id),state: { lists }}} 
                                    className='items'
                                    >
                                    <img alt='itemsImg' className='itemsImg' src={ResizerImgSizeDinamic(imagePath,[600,600])}/>
                                    <p className='itemsDate'>{moment(createdDate).format('MMMM Do YYYY')}</p>
                                    <p className='itemsTitle'>{title}</p>
                                    <p className='itemsDescription'>{shortDescription}</p>
                                </Link>
                            )
                        })}
                    </div>
                </div>}

                <ScrollPagination 
                    classNameList="P-product-page" 
                    fetchData={(selectedPage:any) => this.fetchData(selectedPage)}
                    loading={loading}
                    pageCount={pageCount}
                />
                {/* <PageLoader/> */}
            </section>
        )
    }
}

export default {Blog, Details};
