import * as React from 'react';
import { Link } from 'react-router-dom';

import Settings from 'platform/services/settings';
import ROUTES from 'platform/constants/routes';
import { formatPercenteages, formatPrice } from 'platform/services/helper';
import FavoriteController from 'platform/api/favorite';
import BasketController from 'platform/api/basket';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import UserController from 'platform/api/user';
import ConfirmModal from 'components/confirm-modal';
import { PromotionTypeEnum } from 'platform/constants/enums';
import SearchHistory from 'platform/services/searchHistory';
import GtagData from "../../../../../platform/services/gtagData";
import { ResizerImgSizeStatic } from 'platform/hooks/useImageSize';
import { MdFavorite, MdOutlineFavoriteBorder } from 'react-icons/md';
import MainButton from 'components/Button/mainButton/mainButton';
import Storage from 'platform/services/storage';
import { removefromListPageNumber, sendScrollY, sendfromListPageNumber } from 'platform/hooks/useStorage';
import './style.scss';


interface IProps {
  data: any;
  specialProductId?: number | null;
  callBack?: (id: number,isFavorite:boolean) => void;
  fromList?:boolean
  handleOpenSideBar?:any
}

const SimilarListItem = (props: IProps) => {
  const [data, setData] = React.useState(props.data);
  const [cartLoading, setCartLoading] = React.useState<boolean>(false);
  const [confirmModal, setConfirmModal] = React.useState<boolean>(false);

  const { callBack, fromList } = props;

  const toggleFavorite = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const result = await FavoriteController.AddOrRemove(data.id);
    GtagData(data,"add_to_wishlist")
    result.success && setData({ ...data, isFavorite: !data.isFavorite });
  }

  const addToCart = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if(Settings.guest) {
      window.dispatchEvent(new CustomEvent(DispatcherChannels.toggleAuth));
    } else if(!Storage?.currentAddress) {
      window.dispatchEvent(new Event(DispatcherChannels.openAddress))
    } else {
      setCartLoading(true);
      await BasketController.Change({
        productId: data.id,
        productQuantity: 1,
        isPackage: true,
      });
      data.isAddedToBasket = true
      props.handleOpenSideBar && props.handleOpenSideBar(data?.id)

      window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
      GtagData(data,"view_item")
      setData(data)
      callBack && callBack(data.id,data.isFavorite)
      setCartLoading(false);
    }
  }

  const addSpecialProduct = async () => {
    setConfirmModal(false);
    const result = await UserController.UpdatePreferredProductList({
      newProductId: data.id,
      oldProductId: props.specialProductId ? +props.specialProductId : undefined,
    });
    if (result && result.success) {
      window.routerHistory.push(ROUTES.PROFILE.SPECIAL_PRODUCTS);
    }
  }

  const checkForSearch = () => {
    const query = new URLSearchParams(window.location.search);

    if (fromList) {
      const page = query.get("page");
      sendfromListPageNumber(Number(page) + 1);
      const scrollY = window.scrollY || window.pageYOffset;
      sendScrollY(scrollY)
    } else {
      removefromListPageNumber();
    }
    
    const productSearchData = {
      ...data,
      producer: {
        id: data.productId,
        name: data.productName
      },
    };
    GtagData(data,"view_item")
    query.has('text') && SearchHistory.add(productSearchData.id);
  }

  React.useEffect(() => {
    setData(props.data)
  },[props.data])


  const isDiscount = data?.discountedPrice < data.price;
  const lang:any=Settings.language
  return (
    !!data ? 
    <>
      <Link className="P-products-list-item similarItem"  to={`/${lang}/products/details/${data?.id}/${encodeURIComponent(data?.slugs[lang])}`} onClick={checkForSearch}>
        {isDiscount && <div className='percent_discount_favicon'>{formatPercenteages(data.discountPercent)}</div>}

        <div className="P-image-parent">
        {!!data?.imagePath ? <div className="P-image" style={{ background: `url('${ResizerImgSizeStatic(data.imagePath)}') center/contain no-repeat` }} /> :
          <div className="P-image" />}
        </div>
        <>
          {!Settings.guest && 
            <div className='favourite_item_product'  onClick={toggleFavorite}>
              {!data?.isFavorite ? <MdOutlineFavoriteBorder fontSize={28}/> : <MdFavorite fontSize={28} className='filledIcon'/>}
            </div>}

          {!Settings.guest && props.specialProductId && <i
              onClick={(e) => {
                e.preventDefault(), setConfirmModal(true)
              }}
              className={`P-special-add icon-Group-5532`}
          />}
          <h3 className="P-product-name">{data?.title}</h3>
          <div className="P-price" onClick={(e: React.SyntheticEvent) => e.preventDefault()}>
            <div className="P-product-price-container">
              <span className={`${isDiscount ? 'isDiscounted' : ''}`}>{formatPrice(data.discountedPrice)}</span>
              {isDiscount && <del className="P-without-discount-price">{formatPrice(data.price)}</del>}
            </div>
          </div>

          <MainButton
            loading={cartLoading}
            onClick={addToCart}
            isaddedtobasket={data.isAddedToBasket}
          />
        </>
      </Link>

      {confirmModal && <ConfirmModal
        text={Settings.translations.special_product_confirm}
        onConfirm={addSpecialProduct}
        onClose={() => setConfirmModal(false)}
      />}
    </> : null
  );
};

export default SimilarListItem;
