import React, { useState, useMemo } from 'react';
import Settings from 'platform/services/settings';
import LoaderContent from 'components/loader-content';
import { IContactUsBodyModel } from 'platform/api/support/models';
import SupportController from 'platform/api/support';
import { validateForm } from './service/helper';
import Storage from 'platform/services/storage';

interface IFormValidation {
  valid: boolean;
  errors: Record<string, string>;
}

const Form: React.FC = () => {
  const initialBody: IContactUsBodyModel = {
    firstName: Storage.profile?.firstName || '',
    lastName: Storage.profile?.lastName || '',
    phone: '',
    content: '',
    email: Storage.profile ? Storage.profile.email : '',
  };

  const [body, setBody] = useState<IContactUsBodyModel>(initialBody);
  const [submitted, setSubmitted] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const formValidation: IFormValidation = useMemo(
    () => validateForm?.call(body, submitted),
    [body, submitted]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setBody((prevBody) => ({ ...prevBody, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitted(true);

    if (formValidation.valid) {
      setSubmitLoading(true);
      try {
        const newBody = {
          name: `${body.firstName} ${body.lastName}`,
          email: body.email,
          content: body.content,
          phone:  body.phone
        }
        const res = await SupportController.createRequest(newBody);
        if (res.success) {
          const alertify = await import('alertifyjs');
          alertify.success(`
            <div class="MuiAlert_standardError_child">
                <strong>Success</strong>
                ${Settings.translations.request_success}
            </div>
          `);

          setBody(initialBody); // Reset form fields
          setSubmitted(false);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <form className="contact-us-form" onSubmit={handleSubmit}>
      <div className="name_field">
        <div className="G-main-form-field">
          <p className='placeholder'>{Settings.translations.first_name}</p>
          <input
            type="text"
            name="firstName"
            value={body.firstName}
            onChange={handleChange}
            className={`contact-input ${formValidation.errors.firstName ? 'G-invalid-field' : ''}`}
          />
        </div>

        <div className="G-main-form-field">
          <p className='placeholder'>{Settings.translations.last_name}</p>
          <input
            type="text"
            name="lastName"
            value={body.lastName}
            onChange={handleChange}
            className={`contact-input ${formValidation.errors.lastName ? 'G-invalid-field' : ''}`}
          />
        </div>
      </div>
      <div className="G-main-form-field">
        <p className='placeholder'>{Settings.translations.email}</p>
        <input
          type="email"
          name="email"
          value={body.email}
          onChange={handleChange}
          className={`contact-input ${formValidation.errors.email ? 'G-invalid-field' : ''}`}
        />
      </div>
      <div className="G-main-form-field">
        <p className='placeholder'>{Settings.translations.phone_number_only}</p>
        <input
          type="tel"
          name="phone"
          value={body.phone}
          onChange={handleChange}
          className={`contact-input ${formValidation.errors.phone ? 'G-invalid-field' : ''}`}
        />
      </div>
      <div className="G-main-form-field">
        <p className='placeholder'>{Settings.translations.write_a_message}</p>
        <textarea
          name="content"
          value={body.content}
          onChange={handleChange}
          className={`G-main-textarea ${formValidation.errors.content ? 'G-invalid-field' : ''}`}
        />
      </div>
      <LoaderContent
        type="submit"
        loading={submitLoading}
        className="G-main-button P-form-button"
        id="send_btn"
      >
        <span>{Settings.translations.send}</span>
        <span>{Settings.translations.message}</span>
      </LoaderContent>
    </form>
  );
};

export default Form;
