import * as React from 'react';
import Settings from 'platform/services/settings';
import { IProductListResponseModel } from 'platform/api/product/models/response';
import { formatPercenteages, formatPrice } from 'platform/services/helper';
import BasketController from 'platform/api/basket';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import ROUTES from 'platform/constants/routes';
import { Link } from 'react-router-dom';
import { PromotionTypeEnum } from 'platform/constants/enums';
import GtagData from "../../../../../../../platform/services/gtagData";
import { MdFavorite } from 'react-icons/md';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import FavoriteController from 'platform/api/favorite';
import { ResizerImgSizeStatic } from 'platform/hooks/useImageSize';
import MainButton from 'components/Button/mainButton/mainButton';
import Storage from 'platform/services/storage';
import './style.scss';
import { useRef, useState } from 'react';

interface IProps {
  data: IProductListResponseModel;
  fetchData?: any;
  isAddressAdded: boolean;
  isGuwest: any;
  sliderContral?: any;
  isDragging?: any;
  changeSwip?: any;
}

const OriginalListItem = React.memo(({ data: newData, fetchData, isGuwest }: IProps) => {
  const [cartLoading, setCartLoading] = React.useState(false);
  const [data, setData] = React.useState(newData);

  const [isSwiping, setIsSwiping] = useState(false); // Track if swipe is happening
  const startTouchX = useRef(0); // Store initial touch/mouse position

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const toggleFavorite = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setData({ ...data, isFavorite: !data.isFavorite });
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(async () => {
      if (isGuwest) {
        window.dispatchEvent(new CustomEvent(DispatcherChannels.toggleAuth));
      } else {
        const result = await FavoriteController.AddOrRemove(data.id);
        GtagData(data, "add_to_wishlist");
        result.success && setData({ ...data, isFavorite: result?.data });
      }
    }, 300);
  };

  const addToCart = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (isGuwest) {
      window.dispatchEvent(new CustomEvent(DispatcherChannels.toggleAuth));
    } else if (!Storage?.currentAddress) {
      window.dispatchEvent(new Event(DispatcherChannels.openAddress));
    } else {
      setCartLoading(true);
      await BasketController.Change({
        productId: data.id,
        productQuantity: 1,
        isPackage: false,
      });
      GtagData(data, "add_to_cart");
      fetchData(data.id);
      window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
    }
  };

  const navigateTo = (e: any) => {
    e.preventDefault();
    window.routerHistory.push(ROUTES.PRODUCTS.DETAILS.replace(':id', data.id).replace(':slug', data.slugs[Settings.language]));
  };

  const isDiscount = data?.discountedPrice < data.price;

  const handleTouchStart = (e: React.TouchEvent) => {
    startTouchX.current = e.touches[0].clientX; 
    setIsSwiping(false); // Reset swiping state
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const moveX = e.touches[0].clientX;
    if (Math.abs(moveX - startTouchX.current) > 20) {
      setIsSwiping(true); // If swipe distance is more than 20px, it's considered a swipe
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (!isSwiping) {
      handleNavigation(data.id, data.slugs[Settings.language], e);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    startTouchX.current = e.clientX;
    setIsSwiping(false); // Reset swiping state
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const moveX = e.clientX;
    if (Math.abs(moveX - startTouchX.current) > 20) {
      setIsSwiping(true);
    }
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    if (!isSwiping) {
      handleNavigation(data.id, data.slugs[Settings.language], e);
    }
  };

  const handleNavigation = (id: any, slug: string, e: React.MouseEvent | React.TouchEvent) => {
    const route = ROUTES.PRODUCTS.DETAILS.replace(':id', id).replace(':slug', slug);
    window.routerHistory.push(route);
  };

  React.useEffect(() => {
    setCartLoading(false);
  }, [newData?.isAddedToBasket]);

  return (
    <div
      className="P-home-discounted-products-list-item OriginalListItem"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {isDiscount && <div className="percent_discount_favicon">{formatPercenteages(data.discountPercent)}</div>}

      {!isGuwest && (
        <div className="favourite_item_product" onClick={(e) => toggleFavorite(e)}>
          {!data.isFavorite ? <MdOutlineFavoriteBorder fontSize={28} /> : <MdFavorite fontSize={28} className="filledIcon" />}
        </div>
      )}

      <div className="P-image-parent">
        {!!data.imagePath ? (
          <div className="P-image" style={{ background: `url('${ResizerImgSizeStatic(data.imagePath)}') center/contain no-repeat` }} />
        ) : (
          <div className="P-image" />
        )}
      </div>

      <h3 className="P-product-name">{data?.title}</h3>

      <div className="price_container">
        <span className={`P-price ${isDiscount ? 'isDiscounted' : ''}`}>{formatPrice(data.discountedPrice)}</span>
        {isDiscount && <del className="P-without-discount-price">{formatPrice(data.price)}</del>}
      </div>

      <MainButton
        loading={cartLoading}
        onClick={!newData?.havePackage ? addToCart : navigateTo}
        isaddedtobasket={newData?.isAddedToBasket}
      />
    </div>
  );
});

export default OriginalListItem;
