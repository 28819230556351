import * as React from 'react';

import './style.scss';
import { byPrivateRoute } from 'platform/decorators/routes';
import ROUTES from 'platform/constants/routes';
import { onlyForUsers } from 'platform/guards/routes';
import Layout from '../../components/layout';
import Settings from 'platform/services/settings';
import PaymentController from 'platform/api/payment';
import { CardTypeEnum } from 'platform/constants/enums';
import ArcaCardLogo from 'assets/images/cards/1.svg';
import MasterCardLogo from 'assets/images/cards/2.svg';
import AEBCardLogo from 'assets/images/cards/3.svg';
import VisaCardLogo from 'assets/images/cards/4.svg';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
// import VisaCardImg from 'assets/images/cards/visa.png';
import { SVGComponentRemoveIcon } from 'components/svg-components';
import EmptyStateWallet from 'components/empty-state/my-wallet';
import HelperComponent from 'platform/classes/helper-component';



interface IState {
  list: any[] | null;
  isLoading: boolean;
}

@byPrivateRoute(ROUTES.PROFILE.MY_WALLET, [onlyForUsers])
class MyWallet extends HelperComponent<{}, IState> {
  public componentDidMount() {
    this.getUserCardList();
  }

  public state: IState = {
    list: null,
    isLoading: true,
  };
  
  private getUserCardList = async () => {
    const res = await PaymentController.getUserCards(1);
    if (res && res.success) {
      this.safeSetState({ list: res.data, isLoading: false });
    }
  };

  private createCard = async () => {
    const returnUrl = window.location.pathname + (!!window.location.search ? window.location.search : '?key=true');
    const res = await PaymentController.registerCard(false);

    if (res && res.success) {
      window.location.href = res.data.url;
    }
  };

  private createCardAmeria = async () => {
    const returnUrl = window.location.pathname + (!!window.location.search ? window.location.search : '?key=true');
    const res = await PaymentController.registerCardAmeria(returnUrl);
    if (res && res.success) {
      window.location.href = res.data;
    }
  };

  private removeCard = async (id: string) => {
    const res = await PaymentController.removeCard(id);
    if (res && res.success) {
      await this.getUserCardList();
    }
  };

  private getCardImage = (type: number) => {
    let cardImage;
    switch (type) {
      case CardTypeEnum.Visa:
        cardImage = VisaCardLogo;
        break;
      case CardTypeEnum.MasterCard:
        cardImage = MasterCardLogo;
        break;
      case CardTypeEnum.AmericanExpress:
        cardImage = AEBCardLogo;
        break;
      default:
        cardImage = ArcaCardLogo;
        break;
    }

    return cardImage;
  }

  public render() {
    const { list, isLoading } = this.state;

    return (
      <Layout>
        <div className="P-wallet-title-box G-flex G-flex-align-center G-flex-justify-between G-mb-30">
          <h3 className="G-page-title-left">{Settings.translations.my_wallet}</h3>
          <button id={'add_credit_card_btn'} className="G-main-button" onClick={this.createCard}>{Settings.translations.add_credit_card}</button>
        </div>
        <div className={`P-cards-page ${list && list?.length ? 'P-cards-page-has-card' : ''}`}>
          {!!list ? list.map((item, index) =>
            <div className="P-card-wrap" key={item.id}>
              <div className="P-card-number G-flex ">
                <div className="P-sim" style={{ backgroundImage: `url('${VisaCardLogo}')` }}/>
                <span className="P-card-code">{item.number.slice(8)}</span>
              </div>

              <p className='P-remove' onClick={() => this.removeCard(`${item.id}`)}>
                <SVGComponentRemoveIcon/>
              </p>
            </div>) : <ShimmerLoading colCount={6} childColCount={1} MClass={'adressesShimmer'} className={"productShimmer"}/>}
          {(!list || !list.length) && !isLoading && <EmptyStateWallet />}
        </div>
      </Layout>
    );
  }
}

export default MyWallet;