import Connection from '../../services/connection';
import { IResponse, IPagingResponse } from '../../constants/interfaces';
import { IProductListRequestModel, IProductRelatedListRequestModel, IProductFilterRequestModel, IGetProductPromotionByQuantityModel, IProductSalesRequestModel } from './models/request';
import {
  IProductListResponseModel,
  IProductDetailsResponseModel,
  IProductPriceRangeResponseModel,
  IProductAvailablityResponseModel,
  IProductSearcResponseModel,
  IProductSalesResponseModel
} from './models/response';
import ConnectionServer from 'platform/services/serverConnection';

class ProductController {

  private static controller = 'product';

  public static Search = (search: string): Promise<IResponse<IProductSearcResponseModel>> => {
    const result = Connection.POST({
      body: { search },
      action: 'search',
      controller: ProductController.controller,
    });

    return result;
  };

  public static GetList = (body: IProductListRequestModel): any => {
    const result = Connection.POST({
      body,
      action: 'list',
      controller: ProductController.controller,
    });

    return result;
  };

  public static GetProductSales = (kind: number = 1,body:IProductSalesRequestModel): Promise<IResponse<IPagingResponse<IProductSalesResponseModel>>> => {
    const result = Connection.POST({
      body,
      action: `ProductSalesList/${kind}`,
      controller: ProductController.controller,
    });
    return result;
  }
  public static GetPriceRange = (body: IProductFilterRequestModel): Promise<IResponse<IProductPriceRangeResponseModel>> => {
    const result = Connection.POST({
      body,
      action: 'priceRange',
      controller: ProductController.controller,
    });

    return result;
  };

  public static GetSpecialProducts = (): Promise<IResponse<IProductListResponseModel>> => {
    const result = Connection.GET({
      action: `specialOffers`,
      controller: ProductController.controller,
    });

    return result;
  };

  public static GetAvailablity = (id: number): Promise<IResponse<IProductAvailablityResponseModel>> => {
    const result = Connection.GET({
      action: `availablity/${id}`,
      controller: ProductController.controller,
    });

    return result;
  };

  public static GetProductsList = ({placementType,pageId}:any): Promise<IResponse<IProductAvailablityResponseModel>> => {
    const result = Connection.GET({
      action: `GetList/${placementType}/${pageId}`,
      controller: "Group",
    });

    return result;
  };

  public static GetRelated = (body: any): Promise<IResponse<IPagingResponse<IProductListResponseModel>>> => {
    const result = Connection.POST({
      body,
      action: `related`,
      controller: ProductController.controller,
    });

    return result;
  };

  public static GetDetails = (id: number,slug: string = ''): Promise<IResponse<any>> => {
    const result = Connection.GET({
      action: `${id}/${slug}`,
      controller: ProductController.controller,
    });

    return result;
  };

  public static GetProductPromotionByQuantity = (body: IGetProductPromotionByQuantityModel): Promise<IResponse<any>> => {
    const result = Connection.POST({
      body,
      action: 'getProductByQuantity',
      controller: ProductController.controller
    });

    return result;
  }

  public static GetProducer = (body: IGetProductPromotionByQuantityModel): Promise<IResponse<any>> => {
    const result = Connection.POST({
      body,
      action: 'getProductByQuantity',
      controller: ProductController.controller
    });

    return result;
  }

  // // For SSR to Fill meta tags
  public static ServerDetails = (id: string): any => {
    const result = ConnectionServer.ServerRequest({
      method: 'GET',
      action: 'details',
      query: { id },
      controller: ProductController.controller,
    });

    return result;
  };
};

export default ProductController;
