import React, { useState, useEffect, useCallback } from 'react';
import ClickOutside from '../../../click-outside';
import { Link } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import Storage from 'platform/services/storage';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import './style.scss';
import { ProductSortEnum } from 'platform/api/product/constants/enums';
import CategoryController from 'platform/api/category';

interface ICategory {
  id: number;
  name: string;
  hasChild: boolean;
  children?: ICategory[];
  isSelected:boolean
}

interface ICategoriesState {
  line: number;
  data: ICategory[];
}

interface IProps {
  onClose?: () => void;
  categoryOpenkey?: any;
}

const Categories: React.FC<IProps> = ({ onClose, categoryOpenkey }) => {
  const [categories, setCategories] = useState<any>([]);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null); // Track timeout

  // Handle item click
  const clickItem = useCallback(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event(DispatcherChannels.refetchNewCategoryData));
      onClose && onClose();
    }, 0);
  }, [onClose]);

  const fetchChildCategories = async (id: any, parentLine: any) => {
    try {
      const response = await CategoryController.GetChildCategoryList(id);
  
      if (response?.data?.length) {
        const isParentIdMatch = id === response?.data[0]?.parentId;
  
        if (isParentIdMatch) {
          let newData = categories.slice();
  
          const updatedCategory = {
            line: parentLine + 1,
            data: response?.data,
          };
  
          newData = newData.map((lineCategory: ICategoriesState) => {
            const updatedCategories = lineCategory.data.map((category: ICategory) => {
              const isSelected = category.id === id;

              if(parentLine === lineCategory.line) {
                return { ...category, isSelected }; 
              }                 
              return category; 


            });
  
            return { ...lineCategory, data: updatedCategories };
          });
  
          if (!newData[parentLine] || (parentLine + 1) !== newData[parentLine].line) {
            newData[parentLine] = updatedCategory;
          } else {
            newData[parentLine] = updatedCategory;
          }
  
          newData = newData.slice(0, parentLine + 1);
          console.log(newData, "newData");
  
          if (JSON.stringify(newData) !== JSON.stringify(categories)) {
            setCategories(newData);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching child categories:', error);
    }
  };
  

  const handleHover = (id: any, parentLine: any,hasChild:any) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      if(hasChild) { fetchChildCategories(id, parentLine)}
    }, 150); 

    setDebounceTimeout(timeout); 
  };

  useEffect(() => {
    if (Storage.categories && !categories.length) {
      setCategories([
        {
          line: 1,
          data: Storage.categories,
        },
      ]);
    }
  }, [Storage.categories]);

  const renderCategories = (categoryData: ICategoriesState, childClass?: string) => {
    if (!categoryData.data) return null;

    return (
      <div className={`main-category-list ${childClass || ''}`} key={categoryData.line}>
        {categoryData.data.map((category: ICategory) => (
          <div
            key={category.id}
            className={`category-item`}
            onMouseEnter={() => handleHover(category.id, categoryData.line,category.hasChild)}
          >
            <Link
              to={
                !category.id
                  ? `${ROUTES.PRODUCTS.MAIN}?sortBy=${ProductSortEnum.PriceHighToLow}&hasDiscount=true`
                  : `${ROUTES.PRODUCTS.MAIN}?categoryIds=${category.id}&sortBy=${ProductSortEnum.PriceHighToLow}`
              }
              className={`P-list ${category.isSelected ? 'isSelected' : ''}`}
              onClick={clickItem}
            >
              <h3 className="category-title" title={category.name}>
                {category.name}
              </h3>
              {category.hasChild && <i className="icon-Group-5513" />}
            </Link>
            {category.children?.length && renderCategories({ line: categoryData.line + 1, data: category.children }, 'child-list')}
          </div>
        ))}
      </div>
    );
  };

  if (!categories.length && !categoryOpenkey) return null;

  return (
    <div className="main_categori_popUp">
      <ClickOutside onClickOutside={onClose} className="P-header-categories-wrapper">
        {categories.map(renderCategories)}
      </ClickOutside>
    </div>
  );
};

export default Categories;
