import React, { lazy } from 'react';
import HelperComponent from 'platform/classes/helper-component';
import OriginalListItem from './components/list-item';
import { IProductListResponseModel } from 'platform/api/product/models/response';
import ProductController from 'platform/api/product';
import Slider from "react-slick";
import environment from 'platform/services/environment';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import { SampleNextArrow } from '../arrowComponetn/nextArrow';
import { SamplePrevArrow } from '../arrowComponetn/prevArrow';
import Storage from 'platform/services/storage';
import Settings from 'platform/services/settings';
import './style.scss';
import Sidebar from './components/suggestedItem';
import DispatcherChannels from 'platform/constants/dispatcher-channels';

interface IState {
  data: IProductListResponseModel[] | undefined;
  isAddressAdded:boolean;
  siderBarData:any;
  handleUpdate?:any;
  currentItemId:any
  isDragging:any
};

export enum PlacementEnum {
  Pages = 1,
  Categories,
  Product,
  SuggestedProducts,
}

export enum PageTypeEnum {
  Home = 1,
  Search,
  Basket,
  Favorite,
  Discount,
  NewProducts
}
  
class AllProductsList extends HelperComponent<{params:any,className?:any,handleUpdate?:any}, IState> {

  public state: IState = {
    data: undefined,
    isAddressAdded:false,
    siderBarData:null,
    currentItemId:undefined,
    isDragging:true
  };

  public componentDidMount() {
    this.fetchData();
    this.fetchDefaultAddress();
    window.addEventListener(DispatcherChannels.AlllProductUpdate, this.fetchData);
  }

  private fetchDefaultAddress = async (key?:any) => {
    this.setState({isAddressAdded:!!Storage?.currentAddress?.addressText})
  };

  private fetchData = async () => {
    const {placementType,pageId} = this.props.params
    const result:any = await ProductController.GetProductsList({placementType,pageId});
    this.safeSetState({ data: result?.data || []});
  }

  public settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToScroll: 4,
    nextArrow:<SampleNextArrow/>,
    prevArrow:<SamplePrevArrow/>,
    variableWidth: true,
    variableHeight:true,
    
    swipeToSlide: false,
    draggable: true,
    swipe: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
    ],
  }
  public async handleOpenSideBar(id?:number) {
    const { handleUpdate } = this.props
    const { currentItemId } = this.state
    const result:any = await ProductController.GetProductsList({placementType:PlacementEnum.SuggestedProducts,pageId:id || currentItemId})
    handleUpdate && handleUpdate()
    if(result?.data?.length) { this.setState({siderBarData:result?.data,currentItemId:id || currentItemId}) }
  }

  public render() {
    const { data, isAddressAdded, siderBarData, isDragging } = this.state;
    const { className } = this.props

    const lang:any = Settings.language
    if(!data) {
      return className ? null : <ShimmerLoading MClass={'allProductShimmer'} colCount={5} childColCount={5} className={"productShimmer"}/>
    }

    if(!data.length){
      return null
    }
    
    return (
      data?.map(({title,products}:any,index:number) => {
        if(!products.length)return null;
        return (
          <section key={index} className={`G-page P-home-discounted-products originalSection ${className || ''}`}>
            <h2 className="G-page-title">{title}</h2>
            <div className="P-list-wrapper">
              <Slider
                {...this.settings}
                slidesToShow = {(products.length < 5) ? products.length : 5}
                arrows={true}
               >
                {products.map((item:any) => <div key={item.id}>
                  <OriginalListItem 
                    isGuwest={Settings.guest} isAddressAdded={isAddressAdded} data={item} fetchData={(id:any) => {
                    this.handleOpenSideBar(id)
                    this.fetchData()
                  }}/>
                </div>)}
              </Slider>
            </div>

            <Sidebar 
              lang={lang}
              siderBarData={siderBarData} 
              onClose={() => this.setState({siderBarData:null})} 
              handleUpdate={(id:number) => this.handleOpenSideBar()}
            />
          </section>
        )
      })
    )
  }
};

export default AllProductsList;