import * as React from 'react';

import Settings from 'platform/services/settings';
import Form from './components/form';
import AboutDelivery from './components/about-delivery';
import AboutBonusCard from './components/about-bonus-card';
import AboutMobileApp from './components/about-mobile-app';
import HelperComponent from 'platform/classes/helper-component';
import { formatTime, getViewEnum } from 'platform/services/helper';
import { WeekDaysEnum } from 'platform/constants/enums';
import { IPharmacyBranchListResponseModel } from 'platform/api/pharmacyBranch/models/response';


import './style.scss';

interface IState {
  branches: IPharmacyBranchListResponseModel[];
  hoveredMarkerIndex?: number;
};

import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import { contactEmail, contactHeaderPhoneNumberOne } from 'platform/constants/contact-info';
import { LocationSvg, MailSvg, PhoneSvg, SVGSignOutProfileIcon } from 'components/svg-components';
@byRoute(ROUTES.CONTACT)
class ContactUs extends HelperComponent<{}, IState> {


  public render() {

    return (
      <section className="P-home-contact-us">
        <div className='leftSide'>
          <h2 className="title">{Settings.translations.contact_us}</h2>
          <p className='description'>{Settings.translations.contact_description}</p>
          <p className={"contact-name"}> 
            <PhoneSvg/>
            <a href={`tel:${contactHeaderPhoneNumberOne}`} aria-label="Call us at +374 55556460">
              <p>{contactHeaderPhoneNumberOne.slice(0, 4)} {contactHeaderPhoneNumberOne.slice(4)}</p>
            </a>
          </p>
          <p className={"contact-name"}><MailSvg/>{contactEmail}</p>
          <p className={"contact-name"}><LocationSvg/>{Settings.translations.contact_address}</p>
        </div>
        <Form />
      </section>
    );
  }
}

export default ContactUs;
