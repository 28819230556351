import React, { useState, useEffect, useCallback } from 'react';
import ClickOutside from '../../../click-outside';
import ROUTES from 'platform/constants/routes';
import { Link } from 'react-router-dom';
import Settings from 'platform/services/settings';
import './index.scss';
import BrandController from 'platform/api/brand';
import { CollectionPromo } from '../promoLink';

interface IProps {
  onClose(): void;
}

const MobileHeaderPopUP: React.FC<IProps> = ({ onClose }) => {
  const [promoCollections, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await BrandController.GetPromoHeaderList(CollectionPromo.Promo);
      setData(result?.data?.promoCollections || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const changeRoute = (id: number) => {
    const newUrl = `${ROUTES.PRODUCTS.MAIN}?CollectionId=${id}&sortBy=1&page=1`;
    window.location.href = newUrl;
  };

  return (
    <div className='main_categori_popUp'>
      <ClickOutside onClickOutside={onClose}>
        <div className="P-header-categories-wrapper">
          {promoCollections?.map(({ title, id }) => {
            const query = new URLSearchParams(window.location.search);
            const CollectionId = query.get('CollectionId');
            return (
              <button
                key={id}
                className={`P-link ${Number(CollectionId) === id ? 'activePromo' : ''}`}
                onClick={() => changeRoute(id)}
              >
                {title}
              </button>
            );
          })}
        </div>
      </ClickOutside>
    </div>
  );
};

export default MobileHeaderPopUP;
