import * as React from 'react';
import { Link } from 'react-router-dom';
import { Shared } from 'modules';
import HelperComponent from 'platform/classes/helper-component';
import { byPrivateRoute } from 'platform/decorators/routes';
import ROUTES from 'platform/constants/routes';
import Layout from '../../components/layout';
import FavoriteController from 'platform/api/favorite';
import Settings from 'platform/services/settings';
import { formatPrice, truncateText} from 'platform/services/helper';
import { IFavoriteListResponseModel } from 'platform/api/favorite/models/response';
import SavedBaskets from './pages/saved-baskets';
import SavedBasketItems from './pages/saved-basket-items';
import EmptyState from 'components/empty-state';
import './style.scss';
import { PromotionTypeEnum } from 'platform/constants/enums';
import { MdFavorite, MdOutlineFavoriteBorder } from 'react-icons/md';
import LoaderContent from 'components/loader-content';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import BasketController from 'platform/api/basket';
import GtagData from "../../../../../platform/services/gtagData";
import { ResizerImgSizeDinamic } from 'platform/hooks/useImageSize';
import MainButton from 'components/Button/mainButton/mainButton';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import EmptyStateFavorite from 'components/empty-state/favorite';
import Storage from 'platform/services/storage';

interface IState {
  data: IFavoriteListResponseModel[] | null;
  inProgress: boolean;
  isLoading: boolean;
  cartLoading: boolean;
};

@byPrivateRoute(ROUTES.PROFILE.FAVORITES.MAIN)
class Favorites extends HelperComponent<IState, {}> {
  
  public state: IState = {
    data: null,
    inProgress: false,
    isLoading: true,
    cartLoading:false
  };

  public componentDidMount() {
    this.fetchData();
  }

  private fetchData = async () => {
    const result = await FavoriteController.GetList();
    this.safeSetState({ data: result.data.map(item => ({ ...item, isFavorite: true })), isLoading: false });
  };

  private toggleFavorite = async (e: React.SyntheticEvent, index: number) => {
    if (!this.state.inProgress) {
      this.safeSetState({ inProgress: true });
      e.preventDefault();
      const data = this.state.data as IFavoriteListResponseModel[];
      const result = await FavoriteController.AddOrRemove(data[index].id);

      if (result.success) {
        data.splice(index, 1);
        this.safeSetState({ data, inProgress: false });
      }
    }
  };

  private addToCart = async (e: React.SyntheticEvent,itemId:number,isPackage:boolean) => {
    e.preventDefault();

    if(!Storage?.currentAddress) {
      window.dispatchEvent(new Event(DispatcherChannels.openAddress))
    } else {
      const data = this.state.data as IFavoriteListResponseModel[];
      this.safeSetState({ data, cartLoading: true });
  
      const newData = data.map((val:any) => {
        if(val.id === itemId) {
          val.isAddedToBasket = true
        }
        return val
      })
      
      await BasketController.Change({
        productId: itemId,
        productQuantity: 1,
        isPackage,
      });
      
      GtagData(newData,"add_to_cart");
      window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
      this.safeSetState({ newData, cartLoading: false });
    }
    

  }

  public render() {
    const { data } = this.state;
    const lang:any=Settings.language
    return (
      <Layout>
        <section className="P-profile-favorites">
          <h2 className="P-title G-mb-20 G-flex G-flex-justify-between">
            <p className="P-registrations-title">{Settings.translations.favorites}</p>
          </h2>
          <div className="P-list">
            {data ? (data.length ? data.map((item, index) => {
              return (
                <Link 
                  key={item.id}
                  onClick={()=>GtagData(data,"view_item")} 
                  to={ROUTES.PRODUCTS.DETAILS.replace(':id', item.id).replace(':slug', item.slugs[lang])}
                  className="P-home-discounted-products-list-item"
                >
                  {!Settings.guest && 
                    <div className='favourite_item_product' onClick={e => this.toggleFavorite(e, index)}>
                      {!item.isFavorite ? <MdOutlineFavoriteBorder fontSize={28}/> : <MdFavorite fontSize={28} className='filledIcon'/>}
                    </div>
                  }
              
                  {!!item.promotion.percent && <Shared.Products.DiscountLabel percent={item.promotion.percent} type={item.promotion.promotionType} />}
                  <div className="P-image-parent">
                    <div className="P-image" style={{ background: `url('${ResizerImgSizeDinamic(`${item?.imagePath}`,[400,400])}') center/contain no-repeat` }} />
                  </div>

                  <h3 className="P-product-name">{item.title}
                    {!item.stockQuantity && <h4 className="G-clr-red">{Settings.translations.out_of_stock}</h4>}
                  </h3>
            
                  <div className='price_container'>
                    <span className="P-price">
                      {
                        item.promotion.promotionType === PromotionTypeEnum.Discount ?
                        formatPrice(item.promotion.result) : formatPrice(item.price)
                      }
                    </span>
                    {
                      item.promotion.promotionType === PromotionTypeEnum.Discount && item.promotion.result > 0 ?
                      <del className="P-without-discount-price">{formatPrice(item.price)}</del> : null
                    }
                  </div>
                  <MainButton
                    onClick={(e) => !item?.isPackage ? this.addToCart(e,item.id,item.isPackage) : () => ""}
                    isaddedtobasket={item.isAddedToBasket}
                  />
                </Link>
              )
            }) : null) : null}
          </div>
          {data ? data.length ? null : <EmptyStateFavorite /> : <ShimmerLoading MClass={"favouriteMainShimmer"} colCount={4} childColCount={5} className={"productShimmer"}/>}
        </section>
      </Layout>
    );
  }
}

export default { Favorites, SavedBaskets, SavedBasketItems };
