import './style.scss';
import * as React from 'react';
import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import Storage from 'platform/services/storage';
import { ProductSortEnum } from 'platform/api/product/constants/enums';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {}

@byRoute([ROUTES.CATEGORY.MAIN])
class CategoryMobile extends HelperComponent<IProps, {}> {
  private clickItem = (e: React.SyntheticEvent, category: any,color:string) => {
    e.preventDefault();

    if (category.hasChild) {
      category.color = color
      this.props.history.push({
        pathname: `${ROUTES.CATEGORY.MAIN}details/${category.id}`,
        state: { category }, // Pass the category data as state
      });
    } else {
      this.props.history.push({
        pathname: ROUTES.PRODUCTS.MAIN,
        search: `?categoryIds=${category.id}&sortBy=${ProductSortEnum.PriceHighToLow}`,
      });
    }
  };

  private getRandomColor() {
    const r = Math.floor(Math.random() * 256); // Random red value between 0 and 255
    const g = Math.floor(Math.random() * 256); // Random green value between 0 and 255
    const b = Math.floor(Math.random() * 256); // Random blue value between 0 and 255
    return `rgba(${r}, ${g}, ${b}, 0.2)`;
  }

  public render() {
    if (!Storage?.categories?.length) {
      return (
        <ShimmerLoading
          MClass="allCategoryShimmer"
          colCount={10}
          childColCount={1}
          className="productShimmer"
        />
      );
    }

    return (
      <div className="categoryPage">
        {Storage?.categories?.map((category) => {
          const color = this.getRandomColor();
          return (
            <button
              key={category.id}
              style={{ background: color }}
              className="linkToCategory"
              onClick={(e) => this.clickItem(e, category,color)}
            >
              {category.name}
              {category.hasChild && <i className="icon-Group-5513" />}
            </button>
          );
        })}
      </div>
    );
  }
}

export default withRouter(CategoryMobile);
