import Connection from '../../services/connection';
import { IResponse } from '../../constants/interfaces';
import {ICategoryListResponseModel, ICategoryTreeResponseModel} from './models/response';

const controller = 'category';

class CategoryController {

  public static GetList = (): Promise<IResponse<ICategoryListResponseModel[]>> => {
    const result = Connection.POST({
      action: '',
      body: {
        "categoryIds": [],
        "producerIds": [],
        "activeIngredientIds": [],
        "brandIds": []
      },
      controller:`${controller}/CategoryList`,
    });
    
    return result;
  };

  public static GetChildCategoryList = (id:any) => {
    const result = Connection.POST({
      action: '',
      body: {
        "categoryIds": [],
        "producerIds": [],
        "activeIngredientIds": [],
        "brandIds": [],
        "parentId": id,
      },
      controller:`${controller}/ChildList`,
    });
    
    return result;
  };

  public static GetCategoryTree = (id: any): Promise<IResponse<any>> => {
    const result = Connection.GET({
      action: `Categorytree/${id}`,
      controller,
    });

    return result;
  };
};

export default CategoryController;