export enum GenderEnum {
  NotFilled,
  Male,
  Female,
};


// export enum GenderEnum {
//   Male = 1,
//   Female = 2,
//   NotFilled = 3,
// };