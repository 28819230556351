import * as React from 'react';

import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import HelperPureComponent from 'platform/classes/helper-component';
import Settings from 'platform/services/settings';
import { IStaticResponseModel } from 'platform/api/static/models/response';
import StaticController from 'platform/api/static';

import './style.scss';
import {Link} from "react-router-dom";

interface IState {
  data?: IStaticResponseModel;
}

@byRoute([ROUTES.PRIVACY_POLICY])
class PrivacyPolicy extends HelperPureComponent<{}, IState> {


  public render() {
        return (
          <div>
              <section className="G-page P-privacy-policy-page">
                  <div className="privacy-policy">
                      {Settings.translations.privacy_policy_text}
                  </div>
              </section>
          </div>
        );
    }
}
export default PrivacyPolicy;