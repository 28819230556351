const DispatcherChannels = {

  // Confirmation modal
  SocketConnect: 'SocketConnect',
  checkRoute: 'checkRoute',
  GetBasketCount: 'GetBasketCount',
  ToggleConfirm: 'toggleconfirm',
  UserConfirmed: 'userconfirmed',
  UserCanceled: 'usercanceled',
  checkAddress: 'checkAddress',
  openAddress: 'openAddress',
  logOut: 'logOut',
  refreshCheckout: 'refreshCheckout',
  toggleAuth: 'toggleAuth',

  refetchNewCategoryData:"refetchNewCategoryData",




  // Products

  ProductFilterClear: 'productfilterclear',

  // Cart

  CartItemsUpdate: 'cartitemsupdate',
  CartUpdate: 'CartUpdate',
  AlllProductUpdate: 'AlllProductUpdate',

  productDetailUpdate: 'productDetailUpdate',


  // Storage

  StorageUpdate: 'storageupdate',

  // Resources

  FavoriteUpdate: 'favoriteUpdate',

  YMapsSDKLoad: 'ymapsskdload',
};

export default DispatcherChannels;
