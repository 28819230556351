import { HubConnectionBuilder, HubConnection, LogLevel  } from '@microsoft/signalr';
import environment from './environment';
import Storage from './storage';
import DispatcherChannels from 'platform/constants/dispatcher-channels';


class Socket {
  private static connection: HubConnection | null = null;
  private static cityToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJiMzc0NDFmYi02YjZmLTQ2OTItODY1Zi1mYmE2ZDBjOGU5YmIiLCJ1bmlxdWVfbmFtZSI6IjM3NDk0NzE3MDIxIiwianRpIjoiYTNkOGNkMjktY2RiZC00MjY4LWIzNzEtNGI3NzlkMmQzMDEwIiwiaWF0IjoxNzM0MDg2MDY3LCJSZWNvdmVyeU9yUmVnaXN0ZXIiOiJ0cnVlIiwibmJmIjoxNzM0MDg2MDY2LCJleHAiOjE3NjU2MjIwNjYsImlzcyI6IndlYkFwaSIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6NTAwMC8ifQ.9MVsqDNSsns29iC7J-6nGt4DezT80pnMUDCatagGg_U";

  public static connectChat(): void {
    if (!Socket.connection) {
      Socket.connection = new HubConnectionBuilder()
        .withUrl(`${environment.BASE_URL}chatHub`, {
          accessTokenFactory: () => window.localStorage.getItem("token") || '',
        })
        .withAutomaticReconnect([0, 2000, 10000, 30000]) // Reconnection intervals
        .configureLogging(LogLevel.Information)
        .build();

      Socket.connection.serverTimeoutInMilliseconds = 60000; // 1 minute timeout

      // Event listeners
      Socket.connection.on('NewMessage', (data: any) => {
        window.dispatchEvent(new CustomEvent(DispatcherChannels.SocketConnect, { detail: { data } }));
      });

      Socket.connection.start()
        .then(() => {
          console.log('SignalR connected successfully.');
        })
        .catch((error) => {
          console.error('Error starting SignalR connection:', error);
        });
    }
  }

  public static disconnect(): void {
    if (Socket.connection) {
      Socket.connection.stop()
        .then(() => {
          console.log('SignalR disconnected successfully.');
        })
        .catch((error) => {
          console.error('Error stopping SignalR connection:', error);
        });
      Socket.connection = null;
    }
  }
}

export default Socket;





// import { HubConnectionBuilder, HubConnection } from '@aspnet/signalr';
// import environment from "./environment";

// const cityToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJmMjEyMTlkNS0wNWIzLTQ0ZGMtOGNhYy0yYjcwMDY1NzQwYjkiLCJ1bmlxdWVfbmFtZSI6IiszNzQ5MTk1MzEzMSIsImp0aSI6Ijc3NjEwZmUyLTAwOWYtNDA0ZC1hZjU3LWVkMjk5YmEzNDViMiIsImlhdCI6MTczMzcyNzk1NCwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiVXNlciIsIm5iZiI6MTczMzcyNzk1NCwiZXhwIjoxNzQyMzY3OTU0LCJpc3MiOiJ3ZWJBcGkiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjUwMDIvIn0.9Co70Iq8XMoPOgdvURES2lxMslaUCgrFD5Siq4ICAls"


// class Socket {
//   private static connection: HubConnection | null = null;

//   public static connectChat = async () => {
//     if (!Socket.connection) {
//       Socket.connection = new HubConnectionBuilder()
//         .withUrl(`${environment.BASE_URL_CITY}chat-events`, {
//           accessTokenFactory: () => cityToken || "",
//         })
//         .build();

//       Socket.connection.on("NewMessage", (message) => {
//         console.log("New message received:", message);
//         // Additional logic for handling new messages.
//       });

//       try {
//         await Socket.connection.start();
//         console.log("SignalR connected successfully.");
//       } catch (error) {
//         console.error("Error starting SignalR connection:", error);
//       }
//     }
//   };

//   public static disconnect = async () => {
//     if (Socket.connection) {
//       await Socket.connection.stop();
//       console.log("SignalR disconnected successfully.");
//       Socket.connection = null;
//     }
//   };
// }

// export default Socket;
