import * as React from 'react';
import './style.scss';
import Settings from 'platform/services/settings';
import { WalletEmptySTateSvg } from 'components/svg-components';


const EmptyStateWallet = React.memo(() => {

  return (
    <div className='WalletEmptyMain'>
      <WalletEmptySTateSvg/>
      <span className='P-title'>{Settings.translations.empty_wallet_title}</span>
      <span className='P-desc'>{Settings.translations.empty_wallet_text}</span>
    </div>
  );
})

export default EmptyStateWallet;
