import React, { useState } from "react";
import "./PopupPromo.scss";
import ROUTES from "platform/constants/routes";

const PopupHeader = ({ items }) => {


  console.log(items,"items")

  const changeRoute = (id: number) => {
    const newUrl = `${ROUTES.PRODUCTS.MAIN}?CollectionId=${id}&sortBy=1&page=1`;
    window.location.href = newUrl;
  };

  return (
    <div className="popup">
        <div className="popup-content">
            {items.map((item:any, index:number) => (
                <div className="popup-row" key={item.id} onClick={() => changeRoute(item?.id)}>
                  {item?.title}
                </div>
            ))}
        </div>
    </div>
  );
};

export default PopupHeader;
